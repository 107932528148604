<template>
  <div class="register-container">
    <div class="logo-container">
      <h1 class="logo"><span class="logo-main">Dig <span>Inclusion</span></span><span class="hugr">HUGR</span></h1>
    </div>
    <div v-if="!confirming" class="register-form">
      <div class="inner">
        <h2 v-translate>Sign Up</h2>
        <div v-if="$hugrConfig.disableSignup=='true'">
          <p>Registration disabled.</p>
        </div>
        <div v-else>
        <form @submit.prevent="signUp()">
          <span class="input">
            <FormInput ref="name" idRoot="input_" :label="$gettext('Full Name')" v-model="cNameInput"  :validation="['not-empty']"/>
          </span>

          <span class="input">
            <FormInput ref="email" idRoot="input_" :label="$gettext('Email address')" v-model="cEmail"  :validation="['not-empty', 'email']" />
          </span>

          <span class="input">
            <FormInput ref="password" type="password" idRoot="input_" :label="$gettext('Password')" v-model="password"  :validation="['at-least-8-chars']" />
          </span>

          <span class="input">
            <FormInput ref="passwordConfirm" :initHasError="passConfirmHasError" :initErrorMessage="passConfirmErrorMessage" type="password" idRoot="input_" :label="$gettext('Confirm Password')" v-model="passwordConfirm"  :validation="['at-least-8-chars']" />
          </span>

          <div>
            <input type="checkbox" id="tandc" v-model="termsandconditionsAccepted">
            <label for="tandc">{{$gettext('I accept and agree to the')}} <router-link to='/termsandconditions' target="_blank">{{$gettext('Terms and Conditions')}}</router-link></label>
          </div>
          <div>
            <input type="checkbox" id="license" v-model="licenseAccepted">
            <label for="license">{{$gettext('I accept and agree to the')}} <router-link to='/softwarelicense' target="_blank">{{$gettext('Software License Agreement')}}</router-link></label>
          </div>
          <div>
            <input type="checkbox" id="newsandupdates" v-model="newsandupdates">
            <label for="newsandupdates">{{$gettext('I am happy to be contacted with news and updates, in accordance with our')}} <a href='https://diginclusion.com/privacy-policy/' target="_blank">{{$gettext('Privacy Policy')}}</a></label>
          </div>
          <Button type="secondary" @click.prevent="signUp()" htype="submit">{{$gettext('Sign up!')}}</Button>
        </form>
        </div>
        <Button type="secondary" @click.prevent="$emit('change-form');">{{$gettext('Back to Login')}}</Button>
      </div>
    </div>
    <div v-else class="confirm-form">
      <div class="inner">
        <h2 v-translate>Confirm your account</h2>
        <form @submit.prevent="confirm()">
          <span class="input">
            <FormInput ref="email" idRoot="input_" :label="$gettext('Email')" v-model="cEmail"  :validation="['not-empty']"/>
          </span>
          <span class="input">
              <FormInput ref="confirmCode" idRoot="input_" :label="$gettext('Confirmation Code')" v-model="confirmCode"  :validation="['not-empty']"/>
          </span>
          <Button type="secondary" v-if="!waitingForResponse" @click.prevent="confirm()" htype="submit">{{$gettext('Confirm!')}}</Button>
          <Button type="secondary" v-else @click.prevent="" htype="submit">{{$gettext('Please wait, processing!')}}</Button>
          <Button type="secondary" @click.prevent="$route.query.confirming = false; $emit('change-form');">{{$gettext('Back to Login')}}</Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import md5 from 'md5';
import FormInput from '../components/Form/Input';

export default {
  name: 'RegisterView',
  data() {
    return {
      cNameInput: this.nameInput,
      cEmail: this.email,

      googleLoaded: false,
      password: '',
      passwordConfirm: '',
      passConfirmHasError: false,
      passConfirmErrorMessage: '',
      termsandconditionsAccepted: false,
      waitingForResponse: false,
      newsandupdates: false,
      licenseAccepted: false,
      confirming: this.$route.query.confirming || false,
      confirmCode: this.$route.query.confirmCode || '',
    };
  },
  methods: {
    signUp() {
      this.$refs.email.setValue( this.$refs.email.modelValue.trim() );
      this.$hugrvalidate( [ 'name','email','password','passwordConfirm' ], this ).then( ( { success } ) => {
        if( success ) {
          if ( this.termsandconditionsAccepted && this.licenseAccepted ) {
          if ( this.password == this.passwordConfirm ) {
                 this.$apollo.mutate( {
                    mutation: gql`mutation ($email: String!, $password: String!, $name: String!, $magicCode: String!, $newsInterested: Boolean) {
                      registerAccount(email: $email, password: $password, name: $name, magicCode: $magicCode, newsInterested: $newsInterested) {
                        success,
                        accessToken,
                        refreshToken,
                        user {
                          name,
                          email,
                          aclevel
                        },
                        extraInfo
                      }
                    }`,
                    variables: {
                    name: this.cNameInput,
                    newsInterested: this.newsandupdates,
                    magicCode: this.magicCode,
                    email: this.cEmail,
                    password: md5( `${this.password}:alienFORGERY` ),
                    },
                  } ).then( res => {
                    const login = res.data.registerAccount;
                      if( login.success && !login.extraInfo.includes( "confirm and validate your email" ) ) {
                        this.$session.start();
                        this.$session.set( 'user', {
                          email: login.user.email,
                          name: login.user.name,
                          aclevel: login.user.aclevel,
                          accessToken: login.accessToken,
                          refreshToken: login.refreshToken,
                        } );

                      this.$session.set( 'email', login.user.email );

                      this.$session.set( 'jwt', login.accessToken );
                      localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

                      this.$session.set( 'jwt-refresh', login.refreshToken );
                      localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

                      this.setUser( {
                        email: login.user.email,
                        name: login.user.name,
                        aclevel: login.user.aclevel,
                        accessToken: login.accessToken,
                        refreshToken: login.refreshToken,
                      } );

                      this.$emit( 'success', true );
                    }

                  if ( login.extraInfo !== undefined ) {
                    if ( login.success ) {
                      if ( login.extraInfo.includes( "confirm and validate your email" ) ) {
                        this.confirming = true;
                        this.$alerts.success( this.$gettext( "Almost there!" ), login.extraInfo );
                      } else {
                        this.$alerts.success( this.$gettext( "Success" ), login.extraInfo );
                      }
                    } else {

                      this.$alerts.error( this.$gettext( "Error" ), login.extraInfo );
                    }
                  }
          } ).catch( () => {
              this.$alerts.error( this.$gettext( `Sign Up failed` ), `We think this is a problem on our end.` );
          } );
          } else {
            this.passConfirmHasError = true;
            this.passConfirmErrorMessage = this.$gettext( `Your passwords don't match!` );
          }
        } else {
          this.$alerts.error( this.$gettext( 'You must agree to the Terms and Conditions and the Software License Agreement!' ) );
        }
        }
      } );
    },
    confirm() {
      this.$refs.email.setValue( this.$refs.email.modelValue.trim() );
      this.$hugrvalidate( [ 'email','confirmCode' ], this ).then( ( { success } ) => {
        if( success ) {
          this.waitingForResponse = true;
          this.$apollo.mutate( {
            mutation: gql`mutation ($email: String!, $confirmCode: String!) {
              confirmRegister(email: $email, confirmCode: $confirmCode) {
                success,
                accessToken,
                refreshToken,
                user {
                  name,
                  email,
                  aclevel
                },
                extraInfo
              }
            }`,
            variables: {
              email: this.cEmail,
              confirmCode: this.confirmCode,
            },
          } ).then( res => {
                    this.waitingForResponse = false;

                    const login = res.data.confirmRegister;

                      if( login.success ) {
                        this.$session.start();
                        this.$session.set( 'user', {
                          email: login.user.email,
                          name: login.user.name,
                          aclevel: login.user.aclevel,
                          accessToken: login.accessToken,
                          refreshToken: login.refreshToken,
                        } );

                      this.$session.set( 'email', login.user.email );

                      this.$session.set( 'jwt', login.accessToken );
                      localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

                      this.$session.set( 'jwt-refresh', login.refreshToken );
                      localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

                      this.setUser( {
                        email: login.user.email,
                        name: login.user.name,
                        aclevel: login.user.aclevel,
                        accessToken: login.accessToken,
                        refreshToken: login.refreshToken,
                      } );

                      this.$emit( 'success', true );
                    }

                  if ( login.extraInfo !== undefined ) {
                    if ( login.success ) {
                      this.$alerts.success( this.$gettext( "Success" ), login.extraInfo );
                    } else {
                      this.$alerts.error( this.$gettext( "Error" ), login.extraInfo );
                    }
                  }
            },
          ).catch( () => {
              this.waitingForResponse = false;
              this.$alerts.error( this.$gettext( `Confirmation failed` ), `We think this is a problem on our end.` );
          } );
        }
      } );
    },
    ...mapActions( [ 'setUser' ] ),
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
  },
  props: [ 'magicCode', 'email', 'nameInput' ],
};
</script>

<style lang="scss" scoped>
  div.register-container {
    background: #262E37;
    min-height: calc( 100vh - 50px);
    padding-bottom: 50px;
    color: #000;
    .logo-container {
      margin: auto;
      width: 275px;
      display: block;
      padding: 100px 0 50px;
      h1.logo {
        font-size: 1.25em;
        line-height: 1;
        span.logo-main {
          font-family: 'fs-me-bold', sans-serif;
          color: #FFF;
          border-bottom: 0;
          background-image: url(../assets/images/logo-marque-primary.png);
          background-repeat: no-repeat;
          background-position: left center;
          background-color: transparent;
          background-size: 50px;
          padding-left: 55px;
          height: 46px;
          padding-top: 10px;
          display: inline-block;
          span {
            font-family: 'fs-me-regular', sans-serif;
            font-size: 1em;
            font-weight: 300;
            display:block;
          }
        }
        .hugr {
          color: #FFF;
          border-left: 1px solid #dce4ee;
          vertical-align: top;
          padding: 25px 15px;
          font-size: 25pt;
          font-weight: normal;
          margin-left: 20px;
          top: 15px;
          position: relative;
        }
      }
    }
    .register-form {
      background:#dce4ee;
      max-width: 300px;
      margin: auto;
      padding: 20px;
      div.inner {
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 16pt;
          text-align: center;
        }
        button {
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .confirm-form {
      background:#dce4ee;
      max-width: 300px;
      margin: auto;
      padding: 20px;
      div.inner {
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 16pt;
          text-align: center;
        }
        button {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }
</style>
