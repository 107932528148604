<template>
  <td
    v-if="data"
    :key="data._id"
    ref="cell"
    tabindex="0"
    @copy="innerCopy"
    @paste="doPaste"
    @keyup.enter.self="activate('')"
    @dblclick.stop="activate('')"
    @keydown.self="keyPress"
    @mouseover="doHover"
    @mouseout="unHover"
    @focus="doFocus"
    @blur="doBlur(); exitCellCheck()"
    @keydown.tab="doTab"
    @contextmenu="contextmenu"
    :data-ref="`cell-${row}${column}_${page}`"
    :class="[
      'SSCell',
      (checkForItem(0, 'pass')?'_pass':''),
      (checkForItem(0, 'fail')?'_fail':''),
      (checkForItem(0, 'advisory')?'_advisory':''),
      (checkForItem(0, 'not applicable')?'_na':''),
      (checkForItem(0, 'not present')?'_na':'')
    ]">
    <div v-if="!active&&(shouldRender||forceRender)" class="SSCell_Inner _inactive">
      <template v-for="(item, i) in data.items">
        <div v-if="copyMode || ( i<=3 || validItems.length==5  )&&item.text.trim()!=''"
        :key="'spreadsheetCell-' + i"
        :class="[
          'SSCell_Inner_Item',
          '_inactive',
          (item.done?'done':''),
          (checkFor(item.text, 'pass')?'_pass':''),
          (checkFor(item.text, 'fail')?'_fail':''),
          (checkFor(item.text, 'advisory')?'_advisory':''),
          (checkFor(item.text, 'not applicable')?'_na':''),
          (checkFor(item.text, 'not present')?'_na':'')
        ]">
          <Icon class="SSCell_Inner_Item_Flag" v-if="flagged.indexOf(item.instance)>=0" type="solid" icon="exclamation-triangle" aria-label="Flagged"/>
          {{ item.text }}
          <!-- <span v-if="item.instance && item.instance.trim() != item.text.trim()" class="SSCell_Inner_Item_Identifier">[{{ item.instance }}]</span> -->
          <!-- <input type="checkbox" v-model="item.done" aria-label="Done"/> -->
          <Icon class="SSCell_Inner_Item_Tick" v-if="item.done" type="solid" icon="check" aria-label="Done" :title="item.instance"/>
        </div>
      </template>
      <div v-if="permaItem&&permaItem!==''"
      :class="[
          'SSCell_Inner_Item',
          '_inactive',
          '_permaItem',
        ]">
        {{ permaItem }}
      </div>
      <div v-if="!copyMode&&validItems.length>5" :class="['SSCell_Inner_Item','_inactive', '_other']">
        <!-- <translate :translate-parameters="{number: validItems.length - 4}">%{number} more items</translate> -->
        {{ `${validItems.length-4} ${$gettext('more items')}` }}
      </div>
      <div class="SSCell_Inner_Potential" v-if="!copyMode && potentialIssues.length">
        <span class="SSCell_Inner_Potential_Title">Related issues not in cell:</span>
        <template v-for="(issue, i) in potentialIssues" :key="`potentialIssue-${i}`">
          <div :class="[
            'SSCell_Inner_Item',
            '_inactive',
            '_potential',
          ]">
            {{ issue.reason }}

            <span class="SSCell_Inner_Item_Identifier">[{{ issue.identifier }}]</span>
            <span class="SSCell_Inner_Item_Buttons">
              <button @click="addPotentialIssueToCell( issue )"><Icon type="solid" icon="plus" :aria-label="`Add ${issue.identifier} to cell`"/></button>
            </span>
          </div>
        </template>
      </div>
    </div>
    <div v-if="active&&(shouldRender||forceRender)"
        :class="['SSCell_Inner', '_active', expanded?'_expanded':'']"
        ref="wholeCell" tabindex="-1"
        @blur="exitCellCheck"
      >
      <button :class="!expanded?'SSCell_Inner_Expand':'SSCell_Inner_Compress'" :title="!expanded?'Expand cell':'Compress cell'" @click="() => { expanded = !expanded; }" @blur="exitCellCheck">
        <Icon type="solid" icon="expand-alt" aria-label="Expand cell" v-if="!expanded"/>
        <Icon type="solid" icon="compress-alt" aria-label="Compress cell" v-else/>
      </button>
      <div v-show="(data.items[0].text==''||!isStatus(0))&&!notesOnly" class="SSCell_Inner_StatusBtns">
        <button class="_pass" @click="() => { setStatusAndClose('Pass') }" @blur="exitCellCheck">Pass</button>
        <button class="_fail" @click="() => { setStatusAndKeepAlive('Fail') }" @blur="exitCellCheck">Fail</button>
        <button class="_advisory" @click="() => { setStatusAndKeepAlive('Advisory') }" @blur="exitCellCheck">Advisory</button>
        <button class="_na" @click="() => { setStatusAndClose('Not Applicable') }" @blur="exitCellCheck">Not applicable</button>
      </div>
      <template v-for="(item, i) in data.items" :key="`spreadsheetCell-${i}`" >
        <span v-if="isStatus(i)&&i!=0" :key="'spreadsheetCellBR-' + i" class="SSCell_Inner_Break"></span>
        <span v-if="isOver==i" :key="`fakeItem-${i}`" class="SSCell_Inner_FakeItem" :style="`height: ${movingHeight}px`" @mouseenter="(e) => { mvOver(i, e) }"></span>
        <div
          :ref="`spreadsheetCell-${i}`"
          @keyup.esc.exact.prevent="exitCellHere"
          @keyup.enter.exact.prevent="exitCellEnter"
          @keyup.shift.enter.exact.prevent="exitCellEnter"
          @keyup.tab.exact="exitCellCheck"
          @keydown.up.exact="goUp(i)"
          @keydown.down.exact="goDown(i)"
          @keyup.shift.up.exact.prevent="mvUp(i)"
          @keyup.shift.down.exact.prevent="mvDown(i)"
          @mousedown="(e) => { mvStart(i, e) }"
          @mouseenter="(e) => { if(i<isOver) { mvOver(i, e) } else { mvOver(i+1, e) } }"
          :class="[
            'SSCell_Inner_Item',
            '_active',
            (item.done?'done':''),
            (checkFor(item.text, 'pass')?'_pass':''),
            (checkFor(item.text, 'fail')?'_fail':''),
            (checkFor(item.text, 'advisory')?'_advisory':''),
            (checkFor(item.text, 'not applicable')?'_na':''),
            (checkFor(item.text, 'not present')?'_na':''),
            amChanging==i?'_changing':'',
            !isStatus(i)?'_moveable':'',
            isMoving==i?'_moving':'',
            isOver==i?'_over':'',
            toDelete==i?'_todelete':''
          ]">
          <span v-if="amChanging!=i" @mousedown="(e) => { e.cancelBubble = true; }">
            <AriaContentEditable
              class="SSCell_Inner_Item_Textbox"
              placeholder="type a note or click *+* to add an issue"
              :key="`edit${data._id}${i}`"
              :ref="`edit${data._id}${i}`"
              label="Enter issue notes"
              v-model="item.text"
              :hintText="'This is item '+i+', Cell status is '+(data.items[0].text.trim()!=''?data.items[0].text:'not set')"
              :inputMask="inputMask"
              @blur="exitCellCheck"
            />
            <input v-if="!isStatus(i)&&item.text!=''" type="checkbox" v-model="item.done" aria-label="Done" @blur="exitCellCheck" title="mark item as done"/>
            <button v-else-if="isStatus(i)" class="_change" @click.prevent="changeStatusOn(i)" title="Change status" @blur="exitCellCheck">
              <Icon type="solid" icon="exchange-alt" aria-label="Change status"/>
            </button>
            <span class="SSCell_Inner_Item_Buttons">
              <button v-if="!isStatus(i)&&!item.done" class="_make" @click.prevent="$emit('open-modal', row, column, page, type, i, item.text)" title="Add issue instance" @blur="exitCellCheck">
                <Icon type="solid" icon="plus" aria-label="Add issue instance"/>
              </button>
              <a class="gotoinstance" v-else-if="(typeof item.instance != 'undefined')&&item.instance&&item.instance!='' " href="#" title="Open issue" @blur="exitCellCheck" @click="e => goToIssue( e, item.instance )">
                <Icon type="solid" icon="chevron-right" aria-label="Go to instance (opens in new tab)" />
              </a>
              <button v-if="item.text!=''" :class="['_delete', toDelete==i?'_deleting':'']" @click.prevent="rmItem(i)" @keyup.enter.exact.prevent="rmItem(i)" title="Delete item" @blur="exitCellCheck">
                <Icon type="solid" icon="trash" aria-label="Delete item"/>
                <span v-if="toDelete==i">{{$gettext('Click again to delete')}}</span>
              </button>
            </span>
          </span>
          <span v-else @mousedown="(e) => { e.cancelBubble = true; }">
            <div class="SSCell_Inner_Item_ChangeBtns">
              <button ref="changePass" class="_pass" @click="() => { setStatusAndKeepAlive('Pass', i) }" @blur="exitCellCheck">{{$gettext('Pass')}}</button>
              <button class="_fail" @click="() => { setStatusAndKeepAlive('Fail', i) }" @blur="exitCellCheck">{{$gettext('Fail')}}</button>
              <button class="_advisory" @click="() => { setStatusAndKeepAlive('Advisory', i) }" @blur="exitCellCheck">{{$gettext('Advisory')}}</button>
              <button class="_na" @click="() => { setStatusAndKeepAlive('Not Applicable', i) }" @blur="exitCellCheck">{{$gettext('Not applicable')}}</button>
            </div>
            <button class="_change" @click.prevent="cancelChangeOn(i)" title="Cancel change status">
              <Icon type="solid" icon="times" aria-label="cancel Change status"/>
            </button>
          </span>
          <!-- <button class="link" v-if="item.done&&(typeof item.instance == 'undefined'||item.instance==''||!item.instance)" @click.prevent="$emit('open-modal-link', row, column, page, type, i)">Link with issue instance</button> -->
          <div class="SSCell_Inner_Item_Flagged" v-if="flagged.indexOf(item.instance)>=0">
            <Icon type="solid" icon="exclamation-triangle" aria-label="Flagged"/>
            <span v-if="flag.indexOf(item.instance)>=0">
              {{$gettext('This issue is marked as')}} {{ fixed.indexOf(item.instance)>=0?$gettext('fixed'):$gettext('removed') }}
            </span>
            <span v-if="notOnIssue.indexOf(item.instance)>=0" @mousedown="(e) => { e.cancelBubble = true; }">
              {{ $gettext('This issue is not on this page') }}
              <button @click=" addIssueToPage( item.instance, `edit${data._id}${i}` )">Add it</button>
            </span>
            <span v-if="componentIssueOnPage.indexOf(item.instance)>=0" >
              {{ $gettext('Cannot add a page to a component Issue') }}
            </span>
            <span v-if="pageIssuesOnComponent.indexOf(item.instance)>=0" >
              {{ $gettext('Cannot add a component to a page issue') }}
            </span>
          </div>
        </div>
        <h5 v-show="isStatus(i)" class="SSCell_Inner_Title">Items &amp; notes:</h5>
      </template>
      <div v-if="permaItem&&permaItem!==''"
      :class="[
          'SSCell_Inner_Item',
          '_inactive',
          '_permaItem',
        ]">
        {{ permaItem }}
      </div>
      <div class="SSCell_Inner_Potential" v-if="potentialIssues.length">
        <span class="SSCell_Inner_Potential_Title">Related issues not in cell:</span>
        <template v-for="(issue, i) in potentialIssues" :key="`potentialIssue-${i}`">
          <div :class="[
            'SSCell_Inner_Item',
            '_inactive',
            '_potential',
          ]">
            {{ issue.reason }}

            <span class="SSCell_Inner_Item_Identifier">[{{ issue.identifier }}]</span>
            <span class="SSCell_Inner_Item_Buttons">
              <button @click="addPotentialIssueToCell( issue, false )"><Icon type="solid" icon="plus" :aria-label="`Add ${issue.identifier} to cell`"/></button>
            </span>
          </div>
        </template>
      </div>
      <!-- <span class="SSCell_Inner_Or">or</span>
      <Button type="secondary" size="micro" class="SSCell_Inner_AddIssueBtn" @click.prevent="$emit('open-modal', row, column, type, data.items.length+1)">Add an issue</Button> -->
    </div>
  </td>
</template>

<script>
import gql from 'graphql-tag';

import copy from 'copy-to-clipboard';

import AriaContentEditable from '@/components/Aria/ContentEditable';
import { useIntersectionObserver } from '@vueuse/core';
import { ref } from 'vue';

/**
 * Clones an object using JSON.parse(JSON.stringify)
 * @todo: Change me to `structuredClone` when supported.
 */
function clone( object ) {
  return JSON.parse( JSON.stringify( object ) );
}

export default {
  name: 'SpreadsheetCell',
  mounted() {
    this.updateFromCache();
    document.addEventListener( "visibilitychange", this.onVisibilitychange );
  },
  unmounted() {
    document.removeEventListener( "visibilitychange", this.onVisibilitychange );
  },
  setup() {
    const shouldRender = ref( false );
    const cell = ref();
    const { stop } = useIntersectionObserver(
      cell,
      ( [ { isIntersecting } ] ) => {
        if ( isIntersecting ) {
          shouldRender.value = true;
          stop();
        }
      }, {
        rootMargin: '600px',
      },
    );

    return {
      cell,
      shouldRender,
    };
  },
  data() {
    return {
      cHistory: this.history,
      cPrepop: this.prepop,

      active: false,
      data: clone( this.prepop ),
      hover: false,
      isFocus: false,

      //checks
      flagged: [], //generic flags
      flag: [],
      fixed: [],
      notOnIssue: [],
      componentIssueOnPage: [],
      pageIssuesOnComponent: [],

      potentialIssues: [],

      expanded: false,
      amChanging: null,
      isMoving: null,
      isOver: null,
      toDelete: null,

      mouseInit: [ 0, 0 ],
      elInit: 0,

      movingHeight: 21,

      exitCellCheckTimeout: 0,
    };
  },
  watch:{
    cPrepop() {
      this.$emit( 'update:prepop',this.cPrepop );
    },
    shouldRender() {
      if( this.shouldRender ) {
        this.checkIssues();
      }
    },
  },
  methods: {
    onVisibilitychange() {
      if( this.active ) {
        if ( document.visibilityState == "visible" ) return;

        this.exitCell();
        this.saveCell();
      }
    },
    doTab( e ) {
      setTimeout( () => {
        if( !this.$refs.cell.contains( document.activeElement ) ) {
          if( e.shiftKey ) {
            this.$refs[`edit${this.data._id}${this.data.items.length - 1}`][0].focus();
          } else {
            this.$refs.wholeCell.focus();
          }
        }
      }, 50 );
    },
    changeStatusOn( i ) {
      this.amChanging = i;
      setTimeout( () => {
        this.$refs.changePass[0].focus();
      }, 100 );
    },
    cancelChangeOn( i ) {
      this.amChanging = null;
      setTimeout( () => {
        this.$refs[`edit${this.data._id}${i}`][0].focus();
      }, 100 );
    },
    isStatus( i ) {
      if( this.data.items.hasOwnProperty( i ) ) {
        return [ 'PASS', 'FAIL', 'ADVISORY', 'NOT APPLICABLE', 'NOT PRESENT' ].indexOf( this.data.items[i].text.toUpperCase() ) >= 0;
      }

        return false;

    },
    mvUp( i ) {
      this.mvTo( i, i - 1 );
    },
    mvDown( i ) {
      this.mvTo( i, i + 1 );
    },
    mvTo( old_index, new_index ) {
      while ( old_index < 0 ) {
        old_index += this.data.items.length;
      }
      while ( new_index < 0 ) {
          new_index += this.data.items.length;
      }
      if ( new_index >= this.data.items.length ) {
          let k = new_index - this.data.items.length + 1;
          while ( k-- ) {
              this.data.items.push( undefined );
          }
      }
      this.data.items.splice( new_index, 0, this.data.items.splice( old_index, 1 )[0] );
      this.refreshAll();
      this.$refs[`edit${this.data._id}${new_index}`][0].focus();
    },
    mvStart( i, e ) {
      this.isMoving = i;
      this.isOver = i + 1;
      this.mouseInit = [ e.clientX, e.clientY ];
      this.elInit = this.$refs[`spreadsheetCell-${i}`][0].offsetTop;
      this.movingHeight = this.$refs[`spreadsheetCell-${i}`][0].offsetHeight;

      window.addEventListener( "mousemove", this.mvMove );
      window.addEventListener( "mouseup", this.mvStop );
    },
    mvStop( e ) {
      if( this.isOver != null && this.isMoving != this.isOver ) {
        if( this.isOver > this.isMoving ) {
          this.mvTo( this.isMoving, this.isOver - 1 );
        } else {
          this.mvTo( this.isMoving, this.isOver );
        }
      } else {
        //TODO: why is this here?
      }
      this.movingHeight = 21;
      //clear listeners
      window.removeEventListener( "mousemove", this.mvMove );
      window.removeEventListener( "mouseup", this.mvStop );

      //clear transform
      const item = this.$refs[`spreadsheetCell-${this.isMoving}`][0];
      item.style = "";

      //clear properties
      this.isMoving = null;
      this.isOver = null;
    },
    mvOver( i, e ) {
      if( this.isMoving && i != this.isMoving && this.isOver != i ) {
        this.isOver = i;
      }
    },
    mvMove( e ) {
      if( this.isMoving != null ) {
        const mousePos = [ e.clientX, e.clientY ];
        const diff = [ mousePos[0] - this.mouseInit[0], mousePos[1] - this.mouseInit[1] ];
        const item = this.$refs[`spreadsheetCell-${this.isMoving}`][0];

        const elDiff = this.elInit - this.$refs[`spreadsheetCell-${this.isMoving}`][0].offsetTop;

        diff[1] += elDiff;

        item.style = `transform: translate(${diff[0] / 10}px, ${diff[1]}px);`; //only move on y axis
      }
    },
    refreshAll() {
      for( const i in this.data.items ) {
        if( typeof this.$refs[`edit${this.data._id}${i}`] != 'undefined' ) {
          this.$refs[`edit${this.data._id}${i}`][0].setText( this.data.items[i].text );
        }
      }
    },
    rmItem( i ) {
      if( this.toDelete == i ) {
        const copy = clone( this.data.items[i] );
        this.cHistory.push( { change: 'rmItem', from: copy, to: null, id: this.cellRef } );
        this.data.items[i].text = '';
        this.$refs[`edit${this.data._id}${i}`][0].setText( '' );
        this.data.items.splice( i, 1 );
        this.activate( '' );
        this.refreshAll();
        this.toDelete = null;
      } else {
        this.toDelete = i;
      }
    },
    setStatusAndClose( status, i = -1 ) {
      this.setStatus( status, i );
      this.exitCellHere();
    },
    setStatusAndKeepAlive( status, i = -1 ) {
      this.setStatus( status, i );
      this.activate( '' );
    },
    setStatus( status, i = -1 ) {
      if( i >= 0 ) {
        this.data.items[i].text = status;
      } else if( this.data.items[0].text == '' ) {
        this.data.items[0].text = status;
        this.$refs[`edit${this.data._id}${0}`][0].setText( status );
      } else if( status.toLowerCase().indexOf( this.data.items[0].text.toLowerCase() ) >= 0 ) {
        this.data.items[0].text = status;
        this.$refs[`edit${this.data._id}${0}`][0].setText( status );
      } else {
        this.data.items.unshift( {
          text: status,
        } );
        this.refreshAll();
      }
      this.amChanging = null; //unset change
    },
    updateFromCache() {
      if( !( typeof this.$parent.changeCache != 'undefined' && this.$parent.changeCache.length ) ) return;

      for( const i in this.$parent.changeCache ) {
        if( this.$parent.changeCache.hasOwnProperty( i ) ) {
          const cell = this.$parent.changeCache[i];
          if( cell._id == this.data._id ) {
            this.setItems( cell.items );
            this.$parent.changeCache.splice( i, 1 );
          }
        }
      }
    },
    checkIssues() {
      this.flagged = [];
      this.flag = [];
      this.fixed = [];
      this.notOnIssue = [];
      this.componentIssueOnPage = [];
      this.pageIssuesOnComponent = [];

      for( const item of this.data.items ) {
        if( !item.instance ) continue;
        this.$apollo.query( {
          query: gql`
            query IssueInstance($ident: String) {
              instance: IssueInstance(identifier: $ident) {
                _id,
                status
                component {
                  _id
                }
                page {
                  _id
                }
                others {
                  page {
                    _id
                  }
                }
              }
            }
          `,
          variables: {
            ident: item.instance,
          },
          fetchPolicy: 'network-only',
        } ).then( res => {
          const { instance } = res.data;

          let pages;
          if( instance.page ) pages = [ instance.page._id, ...instance.others.map( i => i.page._id ) ];

          if( instance.status.indexOf( 'closed' ) == 0 ) {
            this.flagged.push( item.instance );
            this.flag.push( item.instance );
            if( instance.status == 'closed-fixed' ) {
              this.fixed.push( item.instance );
            }
          } else if( this.type == "page" && instance.component ) {
            this.flagged.push( item.instance );
            this.componentIssueOnPage.push( item.instance );
          } else if( this.type == "component" && instance.page ) {
            this.flagged.push( item.instance );
            this.pageIssuesOnComponent.push( item.instance );
          } else if( pages && pages.indexOf( this.column ) == -1 ) {
            this.flagged.push( item.instance );
            this.notOnIssue.push( item.instance );
          }
        } ).catch( () => {
          this.$alerts.coded( 'E014', 'F401' ); //see notifications spreadsheet
        } );
      }

      this.potentialIssues = [];
      this.$apollo.query( {
        query: gql`
          query IssuesFromPageOrComponentAndReference($reference: String!, $pageOrComponent: ObjectID!) {
            issues: IssuesFromPageOrComponentAndReference(reference: $reference, pageOrComponent: $pageOrComponent) {
              _id
              identifier
              reason
            }
          }
        `,
        variables: {
          reference: this.rowReference,
          pageOrComponent: this.column,
        },
        fetchPolicy: 'network-only',
      } ).then( res => {
        const { issues } = res.data;

        const issuesOnCell = this.data.items.filter( i => i.instance ).map( i => i.instance.trim() ).filter( i => i != '' );

        for( const issue of issues ) {
          if( issuesOnCell.indexOf( issue.identifier.trim() ) == -1 ) this.potentialIssues.push( issue );
        }
      } ).catch( () => {
        this.$alerts.coded( 'E088', 'F404' );
      } );
    },
    addIssueToPage( identifier, keyToFocus ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addIssueInstancePageByIdentifier($identifier: String!, $page: ObjectID!) {
            instance: addIssueInstancePageByIdentifier(identifier: $identifier, page: $page) {
              _id
            }
          }
        `,
        variables: {
          identifier,
          page: this.column,
        },
      } ).then( () => {
        this.$alerts.success( "Issue added to page" );
        this.checkIssues();
        if( this.$refs[keyToFocus][0] ) {
          this.$refs[keyToFocus][0].focus();
        } else {
          this.$refs.cell.focus();
        }
      } ).catch( () => {
        this.$alerts.coded( 'E087', 'F403' ); //see notifications spreadsheet
      } );
    },
    addPotentialIssueToCell( issue, save = true ) {
      if( this.data.items.length == 0 || ( this.data.items.length == 1 && this.data.items[0].text == '' ) ) {
        this.data.items.push( {
          text: 'Fail',
          instance: '',
          done: false,
        } );
      } else if( this.data.items[0].text.toLowerCase() != 'fail' ) { //todo maybe look at severity
        this.$confirm.make( 'Cell is not currently marked as fail', 'Do you want to change this cell to fail?' ).then( result => {
          if( result ) {
            if( [ 'pass', 'advisory', 'todo', 'not applicable' ].indexOf( this.data.items[0].text.toLowerCase() ) >= 0 ) {
              this.data.items[0].text = 'Fail';
            } else {
              this.data.items.unshift( {
                done: false,
                text: 'Fail',
              } );
            }
          }
        } );
      }
      this.data.items.push( {
        text: issue.reason,
        instance: issue.identifier,
        done: true,
      } );
      if( save ) {
        this.saveCell();
      }
      this.checkIssues();
    },
    inputMask( { target } ) {
      const input = target.innerText.toLowerCase();

      switch ( input ) {
        case 'n/a':
          target.innerText = 'Not Applicable';

          break;
        case 'pass':
          target.innerText = 'Pass';

          break;
        case 'fail':
          target.innerText = 'Fail';

          break;
      }

    },
    putData( d ) {
      this.data = d;
      this.checkIssues();
    },
    keyPress( e ) {
      const ALPHABET_A = 65;
      const ALPHABET_Z = 90;
      const BACKSPACE = 8;
      const DELETE = 46;

      if( !e.ctrlKey && !e.metaKey ) {
        if( [ 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight' ].indexOf( e.key ) > -1 ) {
          this.$emit( 'traverse', e );
        } else if( e.keyCode >= ALPHABET_A && e.keyCode <= ALPHABET_Z && !this.active ) { //is a character, and not currently active (don't add several items)
          this.activate( e.key );
        } else if( e.keyCode == BACKSPACE || e.keyCode == DELETE ) {
          this.clear();
        }
      } else {
        //TODO: why is this here?
      }
    },
    activate( a, instance = null, done = null ) {
      this.$emit( 'setHint', 'use up and down arrow keys to traverse items, press shift+enter to add a new item, press enter or escape to exit cell' );
      this.active = true;
      //check last item has text
      let numElements = this.data.items.length;
      let newAt = numElements - 1;
      if( newAt < 0 ) {
        newAt = 0;
        this.data.items[newAt] = { text: "" };
      }
      if( this.data.items[newAt].text != "" ) { //make new and populate if necessary
        const newItem = { text: a };
        if ( instance ) {
          newItem.instance = instance;
        }

        if ( done ) {
          newItem.done = done;
        }

        //then add new item and focus it
        this.data.items.push( newItem );

        numElements++;
        newAt++;
      } else { //don't make new but populate if necessary
        this.data.items[newAt].text = a;
      }
      setTimeout( () => { //wait for creation and/or activation
        if( this.$refs[`edit${this.data._id}${newAt}`] ) {
          this.$refs[`edit${this.data._id}${newAt}`][0].focus();
        }
      },100 );
    },
    /**
     * Checks whether cell needs to be closed after blur event (blur could be entering)
     */
    exitCellCheck() {
      setTimeout( () => { //focus jumps to body before back to element, timeout fixes, grr
        if( document.activeElement.tagName == 'BODY' ) {
          if( this.exitCellCheckTimeout < 5 ) {
            this.exitCellCheckTimeout++;
            this.exitCellCheck();
          } else {
            this.exitCell();
            this.exitCellCheckTimeout = 0;
          }
        } else if( this.$refs.cell && !this.$refs.cell.contains( document.activeElement ) ) {
          this.exitCell();
          this.exitCellCheckTimeout = 0;
        } else {
          this.exitCellCheckTimeout = 0;
        }
      }, 100 );
    },
    exitCellEnter( e ) {
      if( e.shiftKey ) { //add new
        this.activate( '' );
      } else { //exit
        this.exitCell();
        let { children } = this.$refs.cell.parentElement.nextElementSibling;
        if( children[0].classList.contains( 'SSBody_Section_Header' ) ) {
          children = this.$refs.cell.parentElement.nextElementSibling.nextElementSibling.children;
        }
        const nextCell = children[this.$refs.cell.cellIndex];
        if( nextCell.tagName == 'TD' ) {
          nextCell.focus(); //focus next
        } else {
          this.$refs.cell.focus();
        }
      }
    },
    exitCellHere() {
      this.exitCell();
      this.$refs.cell.focus();
    },
    exitCell() {
      this.active = false; //deactivate
      this.expanded = false; //compress
      this.amChanging = null; //cancel changes
      this.toDelete = null; //cancel to delete
      for( const i in this.data.items ) { //clear unpopulated items
        if( i > 0 && this.data.items[i].text.trim() == '' ) {
          this.data.items.splice( i, 1 );
        }
      }
      this.saveCell();
    },
    saveCell() {
      this.cPrepop.items = this.data.items;
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateSpreadsheetCell($id: ObjectID!, $items: [SpreadsheetCellItemInput]) {
            cell: updateSpreadsheetCell(id: $id, items: $items) {
              _id,
              row,
              column,
              type,
              sheet
              items {
                done,
                text,
                instance,
              }
            }
          }
        `,
        variables: {
          id: this.cPrepop._id,
          items: this.data.items.map( item => {
            return {
              done: item.done,
              text: item.text,
              instance: item.instance,
            };
          } ),
        },
      } ).then( res => {
        this.$emit( 'changed', res.data.cell );
      } ).catch( () => {
        this.$alerts.coded( 'E015', 'F402' ); //see notifications spreadsheet
      } );
    },
    instanceAdded( item, i, severity ) {
      if( this.data.items.length == 1 && this.data.items[0].text == '' ) {
        this.data.items = [
          {
            done: false,
            text: severity > 0 ? "Fail" : "Advisory",
          },
          {
            done: true,
            instance: i,
            text: i,
          },
        ];
      } else if( typeof this.data.items[item] == 'undefined' || this.data.items[item].text == '' ) {
        if( this.data.items[0].text.toLowerCase() != 'fail' && severity > 0 ) {
          this.$confirm.make( 'Cell is not currently marked as fail', 'Do you want to change this cell to fail?' ).then( result => {
            if( result ) {
              this.data.items.unshift( {
                done: false,
                text: 'Fail',
              } );
              // this.data.items[0].text = 'Fail';
            }
          } );
        }
        this.data.items.push( {
          done: true,
          instance: i,
          text: i,
        } );
      } else {
        if( this.data.items[0].text.toLowerCase() != 'fail' && i.toLowerCase().indexOf( 'note' ) != 0 && severity > 0 ) {
          this.$confirm.make( 'Cell is not currently marked as fail', 'Do you want to change this cell to fail?' ).then( result => {
            if( result ) {
              this.data.items.unshift( {
                done: false,
                text: 'Fail',
              } );
              // this.data.items[0].text = 'Fail';
            }
          } );
        }
        this.data.items[item].instance = i;
        this.data.items[item].done = true;
      }
      this.exitCell();
    },

    goUp( i ) {
      if( i > 0 ) {
        const ref = `edit${this.data._id}${i - 1}`;
        if( this.$refs[ref] ) {
          this.$refs[ref][0].focus();
        }
        //TODO go to end
      } else {
        //do nothing
      }
    },
    goDown( i ) {
      if( i < this.data.items.length ) {
        const ref = `edit${this.data._id}${i + 1}`;
        if( this.$refs[ref] ) {
          this.$refs[ref][0].focus();
        }
        //TODO go to end
      } else {
        //do nothing
      }
    },
    cloneData() {
      return clone( this.data );
    },
    copy() {
      return clone( this.data.items ); //make a copy of it not pointer
    },
    doCopy() {
      copy( JSON.stringify( this.copy() ) );
      this.$alerts.success( 'cell copied to clipboard' );
    },
    innerCopy( e ) {
      this.$emit( 'copyCell', e, {
        cell: this.$refs.cell,
        row:this.row,
        column:this.column,
        type:this.type,
        page: this.page,
      } );
    },
    clear( ) {
      this.cHistory.push( { change: 'clearCell', from: this.copy(), to: [], id: this.cellRef } );

      this.data.items = [];
      this.checkIssues();
      this.saveCell();
    },
    paste( data, record = true, focus = true ) {
      // Set record to false to avoid adding to history when calling `paste()`.
      if ( record ) this.cHistory.push( { change: 'pasteCell', from: this.copy(), to: data.items, id: this.cellRef } );
      
      const parsed = clone( data );
      if( typeof parsed !== 'object' ) return;

      this.data.items = [];
      for( const i in parsed ) {
        if( typeof parsed[i].text == 'string' ) {
          this.data.items[i] = parsed[i];
        }
      }
      this.checkIssues();
      this.saveCell();
      this.$forceUpdate();

      if( focus ) {
        setTimeout( () => {
          this.focus();
        }, 100 );
      }

      this.$alerts.success( 'cell pasted from clipboard' );
    },
    doPaste( { clipboardData } ) {
      if( !this.active ) {
        const data = clipboardData.getData( 'text/plain' );
        let parsed;

        try {
          parsed = JSON.parse( data );
        } catch( e ) {
          // We've had an error. It may not be JSON (ie: just plaintext),
          // so we'll try to create it manually.
          parsed = [ { done: null, text: data, instance: null, __typename: "SpreadsheetCellItem" } ];
        }
        this.paste( parsed );
      }
    },
    setItems( items ) {
      this.data.items = items;
      this.cPrepop.items = this.data.items;
    },
    addItem( item ) {
      this.data.items.push( item );
      this.saveCell();
    },
    doHover( e ) {
      this.hover = true;
    },
    unHover( e ) {
      this.hover = false;
    },
    doFocus( e ) {
      this.isFocus = true;
      this.$emit( 'setHint', 'Start typing or press enter to edit cell' );
      this.$emit( 'focus' );

      this.checkIssues();
    },
    doBlur( e ) {
      this.isFocus = false;
    },
    contextmenu( e ) {
      if( !this.active ) {
        e.preventDefault();
        this.$emit( 'context', e, {
          cell: this.$refs.cell,
          row:this.row,
          column:this.column,
          type:this.type,
          page: this.page,
        } );
      }
    },
    // doCopyToRow: function(e) {
    //   this.isFocus = false;
    //   this.$emit('copyToRow', this.$refs.cell, this.copy())
    //   this.$refs.cell.focus();
    // },
    focus() {
      this.$refs.cell.focus();
      // this.$refs.copytorowbtn.focus();
    },

    checkFor( string, phrase ) {
      const regex = new RegExp( `^${phrase.toUpperCase()}[^A-Za-z0-9]` );

      return ( !!string.toUpperCase().match( regex ) ) || string.toUpperCase() == phrase.toUpperCase();
    },
    checkForItem( item, phrase ) {
      if( typeof this.data.items[item] != 'undefined' ) {
        this.checkFor( this.data.items[item].text, phrase );
      } else {
        return false;
      }
    },
    goToIssue( e, identifier ) {
      e.preventDefault();
      this.$emit( 'goToIssue', identifier );
    },
  },
  computed: {
    validItems() {
      return this.data.items.filter( i => i.text != "" );
    },
    /**
     * Returns the string used to reference this cell by
     * the parent spreadsheet.
     */
    cellRef() {
      return `cell-${this.row}${this.column}_${this.page}`;
    },
  },
  props: {
    history: {
      type: Array,
      required: true,
    },
    row: {
      type: String,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
    prepop: {
      type: Object,
      required: true,
    },
    notesOnly: {
      type: Boolean,
      default: false,
    },
    rowReference: {
      type: String,
      required: true,
    },
    copyMode: {
      type: Boolean,
      required: true,
    },
    permaItem: {
      type: String,
      required: false,
    },
    forceRender: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AriaContentEditable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';
  $cell-width: 250px;

  .SSCell {
    border: 1px solid $hugr-colours-grey;
    vertical-align: top;
    max-width: $cell-width;
    width: $cell-width;
    position: relative;

    &:focus, &:focus-within {
      outline: 1px solid $hugr-colours-tertiary;
    }

    &_Inner {
      padding: 10px 5px;
      max-width: $cell-width;
      width: $cell-width;
      box-sizing: border-box;

      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      border-radius: 5px;

      position: relative;
      left: 0;
      top: 0;
      transition: width 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  height 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  top 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  left 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  border 0.2s 0.5s;

      &._active {
        position: absolute;
        left: -50px;
        top: -50px;
        width: calc(100% + 100px);
        max-width: calc(100% + 100px);
        min-height: calc(100% + 100px);
        border: 1px solid $hugr-colours-tertiary;
        z-index: 9999;

        box-shadow: black 0px 0px 20px -10px;

        transition: width 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  height 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  top 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  left 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s,
                  border 0s 0s;
      }

      &._expanded {
        position: fixed;
        top: 130px;
        left: 100px;
        width: calc(100% - 200px);
        height: calc(100vh - 260px);

        // transition: width 1s 0s,
        //             height 1s 0s,
        //             top 1s 0s,
        //             left 1s 0s,
        //             border 0s 0s;
      }

      &_Expand, &_Compress {
        background: #FFFFFF;
        border: 1px solid #930056;
        border-radius: 20px;
        width: 26px;
        height: 26px;
        position: absolute;
        top: -12px;
        left: -12px;
        z-index: 99999;
        &:focus, &:hover {
          cursor: pointer;
          background: $hugr-colours-grey;
        }
      }

      &_StatusBtns {
        button {
          border: 1px solid;
          background: #FFF;
          position: relative;
          margin-bottom: 8px;
          margin-right: 8px;
          border-radius: 10px;
          padding: 3px 10px;
          font-size: 0.8em;

          &._pass { background: #d1ffd1; border: 1px solid #cbd2da;  }
          &._fail { background: #ffbdbd; border: 1px solid #cbd2da;  }
          &._advisory { background: #f3f3a9; border: 1px solid #cbd2da;  }
          &._na { background: #cbd2da; border: 1px solid #cbd2da;  }

          &:hover, &:focus {
            border: 1px solid #8f9eb0;
            cursor: pointer;
          }
        }
      }

      &_Or {
        text-align: center;
        display: block;
        margin: 10px;
        font-size: 0.8em;
      }

      &_AddIssueBtn {
        width: 100%;
        text-align: center !important;
        position: relative;
        margin-bottom: 5px;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 0.8em;
      }

      &_Break {
        border-bottom: 1px solid #cbd2da;
        width: 100%;
        display: block;
        margin: 12px 0;
      }

      &_Title {
        margin: 8px 2px;
      }

      &_FakeItem {
        display: block;
        width: 100%;
        height: 21px;
        background: #cbd2da;
        border-radius: 8px;
        margin-bottom: 5px;
      }

      &_Potential {
        border-top: 1px solid $hugr-colours-grey;
        margin-top: 18px;
        padding-top: 18px;
        &_Title {
          display: block;
          margin-bottom: 8px;
          font-size: 0.8em;
        }
      }

      &_Item {
        position: relative;
        margin-bottom: 5px;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 0.8em;

        &_Flagged {
          color: $hugr-colours-red;
          border-top: 1px solid;
          margin-top: 5px;
          padding: 5px 0;
        }

        &_Identifier {
          font-size: 0.7em;
          display: block;
        }

        &._active {
          //default white with border
          border: 1px solid $hugr-colours-grey;
          transition: border 0.5s 0s;
          &._moveable {
            transition: border-left 0.5s 0s;
            cursor: grab;
            &:hover {
              border-left: 10px solid #8f9eb0;
              &:focus-within {
                border-left: 10px solid $hugr-colours-tertiary;
              }
            }
            &._moving {
              // opacity: 0.4;
              cursor: grabbing !important;
              box-shadow: black 0px 0px 7px -3px;
              z-index: 99999;
              position: absolute;
              background: #FFF;
              width: calc( 100% - 41px);
              border: 1px solid $hugr-colours-tertiary;
              border-left: 10px solid $hugr-colours-tertiary;

              pointer-events: none; //allow mouseenter passthrough
            }
            &._over:not(._moving) {
              // margin-top: 28px;
              &:hover {
                border-left: 1px solid #8f9eb0;
              }
              // &:before {
              //   display: block;
              //   position: absolute;
              //   content: "";
              //   width: 100%;
              //   height: 21px;
              //   background: #cbd2da;
              //   border-radius: 8px;
              //   left: 0px;
              //   top: -25px;
              // }
            }
          }

        }
        &._inactive {
          //default blue
          background: #d1efff;
          border: 1px solid #d1efff;

          max-height: 29px;
          overflow: hidden;
          padding-right: 1rem; /* space for ellipsis */
          text-overflow: ellipsis;
        }
        //other colours
        &._pass { background: #d1ffd1; border: 1px solid #d1ffd1; }
        &._fail { background: #ffbdbd; border: 1px solid #ffbdbd; }
        &._advisory { background: #f3f3a9; border: 1px solid #f3f3a9; }
        &._na { background: #cbd2da; border: 1px solid #cbd2da; }
        &._other { background: #f1f3f5; border: 1px solid #cbd2da; }
        &._changing { background: #f1f3f5; border: 1px solid #cbd2da;}
        &._todelete { border: 1px solid #ff0000 !important; }

        &._potential {
           background: lighten( $hugr-colours-secondary, 20% );
           border: 1px solid lighten( $hugr-colours-secondary, 20% );
        }
        &._permaItem {
          background: lighten( $hugr-colours-grey, 13% );
          border: 1px solid $hugr-colours-grey;
          width: 100%;
          box-sizing: border-box;
        }

        &_ChangeBtns {
          button {
            border: 1px solid;
            background: #FFF;
            position: relative;
            margin-right: 8px;
            border-radius: 10px;
            padding: 3px 10px;
            font-size: 1em;

            &._pass { background: #d1ffd1; border: 1px solid #cbd2da;  }
            &._fail { background: #ffbdbd; border: 1px solid #cbd2da;  }
            &._advisory { background: #f3f3a9; border: 1px solid #cbd2da;  }
            &._na { background: #cbd2da; border: 1px solid #cbd2da;  }

            &:hover, &:focus {
              border: 1px solid #8f9eb0;
              cursor: pointer;
            }
          }
        }

        &_Tick {
          position: absolute;
          right: 5px;
          bottom: 5px;
        }

        input[type="checkbox"] {
          position: absolute;
          right: 5px;
          top: 4px;
          margin: 0;
          &:before {
            content: "";

          }
        }
        ._change {
          position: absolute;
          right: 3px;
          top: 4px;

          border: none;
          background: transparent;
          color: $hugr-colours-primary;
          cursor: pointer;
          height: 15px;
          padding: 0 2px;

          &:focus, &:hover {
            color: $hugr-colours-tertiary;
          }
        }

        &_Textbox {
          > span {
            // position: absolute;
            // margin-left: 10px;
            min-height: auto;
            width: calc( 100% - 40px);
            cursor: text;
            &:focus {
              outline: none;
            }
          }
        }
        &_Buttons {
          position: absolute;
          top: 3px;
          right: 20px;
          button {
            border: none;
            background: transparent;
            color: $hugr-colours-primary;
            cursor: pointer;
            height: 19px;
            padding: 3px;
            top: -2px;
            position: relative;

            &:focus, &:hover {
              color: $hugr-colours-tertiary;
            }

            &._make {
              background: #FFFFFF;
            }

            &._delete {
              transition: width 0.5s 0;
              &:hover, &:focus {
                color: #b9141b;
              }
              &._deleting {
                background: #FFFFFF;
                border-radius: 3px;
              }
            }
          }
          a.gotoinstance {
            color: black;
            position: relative;
            padding: 0 2px;
            // left: 8px;
            &:focus, &:hover {
              color: $hugr-colours-tertiary;
            }
          }
        }

        &:hover {
          border: 1px solid darken($hugr-colours-grey, 20%);
        }
        &:focus-within {
          border: 1px solid $hugr-colours-tertiary;
        }
      }
    }
  }

  ._darkMode .SSCell {
    border: 1px solid lighten($hugr-colours-primary, 10%);

    &:focus, &:focus-within {
      outline: 1px solid $hugr-colours-secondary;
    }

    &_Inner {
      background: $hugr-colours-primary;
      border: 1px solid $hugr-colours-primary;
      color: #000;

      &._active {
        border: 1px solid $hugr-colours-secondary;
      }

      &_Potential {
        &_Title {
          color: $hugr-colours-grey;
        }
      }

      &_Title {
        color: $hugr-colours-grey;
      }

      &_Expand {
        border: 1px solid $hugr-colours-secondary;
        background: $hugr-colours-primary;
        color: $hugr-colours-grey;
      }

      &_Item {
        &:focus-within {
          border: 1px solid $hugr-colours-secondary;
        }
        &._active {
          &._moveable {
            &:hover {
              &:focus-within {
                border-left: 10px solid $hugr-colours-secondary;
              }
            }
          }
        }

        &_Buttons {
          button {
            &._make {
              color: $hugr-colours-grey;
              background: $hugr-colours-primary;
            }
            &._delete {
              color: $hugr-colours-grey;
            }
          }
          a.gotoinstance {
            color: $hugr-colours-grey;
          }
        }

        &._fail, &._pass, &._na, &._advisory {
          color: $hugr-colours-primary;
          button {
            &._delete {
              color: $hugr-colours-primary;
            }
          }

          .textbox {
            color: $hugr-colours-primary;
          }
        }

        &_Flagged {
          color: $hugr-colours-red-darkmode;
        }
      }
    }

  }

</style>
