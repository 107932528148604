<template>
  <div v-if="type=='line'" :class="[`SeverityLine`, direction == 'vertical' ? `_flex` : ``]">
    <span :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']" :style="[direction == `vertical` ? `height:${getPercent('advisory')}%; width: 16px; min-height: 4px;` : `width:${getPercent('advisory')}%`]">
      <span :class="[`SeverityLine_Item_Inner`, `_advisory`,  direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="advisorytt?.showTooltip()"
          @mouseleave="advisorytt?.hideTooltip()"
          @focus="advisorytt?.showTooltip()"
          @blur="advisorytt?.hideTooltip()">
      </span>
      <Tooltip ref="advisorytt" :text="advisoryText" :title="`${advisory} Advisory issues`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
    </span>

    <span :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']" :style="[direction == `vertical` ? `height:${getPercent('low')}%; width: 16px; min-height: 4px;` : `width:${getPercent('low')}%`]">
      <span :class="[`SeverityLine_Item_Inner`, `_low`, direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="lowtt?.showTooltip()"
          @mouseleave="lowtt?.hideTooltip()"
          @focus="lowtt?.showTooltip()"
          @blur="lowtt?.hideTooltip()">
      </span>
      <Tooltip ref="lowtt" :text="lowText" :title="`${low} Low issues`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
    </span>

    <span :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']" :style="[direction == `vertical` ? `height:${getPercent('medium')}%; width: 16px; min-height: 4px;` : `width:${getPercent('medium')}%`]">
      <span :class="[`SeverityLine_Item_Inner`, `_medium`, direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="mediumtt?.showTooltip()"
          @mouseleave="mediumtt?.hideTooltip()"
          @focus="mediumtt?.showTooltip()"
          @blur="mediumtt?.hideTooltip()">
      </span>
      <Tooltip ref="mediumtt" :text="mediumText" :title="`${medium} Medium issues`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
    </span>

    <span :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']" :style="[direction == `vertical` ? `height:${getPercent('high')}%; width: 16px; min-height: 4px;` : `width:${getPercent('high')}%`]">
      <span :class="[`SeverityLine_Item_Inner`, `_high`, direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="hightt?.showTooltip()"
          @mouseleave="hightt?.hideTooltip()"
          @focus="hightt?.showTooltip()"
          @blur="hightt?.hideTooltip()">
      </span>
      <Tooltip ref="hightt" :text="highText" :title="`${high} High issues`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
    </span>

    <span :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']" :style="[direction == `vertical` ? `height:${getPercent('critical')}%; width: 16px; min-height: 4px;` : `width:${getPercent('critical')}%`]">
      <span :class="[`SeverityLine_Item_Inner`, `_critical`, direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="criticaltt?.showTooltip()"
          @mouseleave="criticaltt?.hideTooltip()"
          @focus="criticaltt?.showTooltip()"
          @blur="criticaltt?.hideTooltip()">
      </span>
      <Tooltip ref="criticaltt" :text="criticalText" :title="`${critical} Critical issues`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
    </span>
  </div>
  <div v-if="type=='pills'" class="SeverityPills">
    <Pill :text="advisory" :type="advisory!=0?'advisory':'inactive'" :circle="true" :tooltip="advisoryText" :tooltipTitle="$gettext('Advisory severity')"/>
    <Pill :text="low" :type="low!=0?'positive':'inactive'" :circle="true" :tooltip="lowText" :tooltipTitle="$gettext('Low severity')"/>
    <Pill :text="medium" :type="medium!=0?'warning':'inactive'" :circle="true" :tooltip="mediumText" :tooltipTitle="$gettext('Medium severity')"/>
    <Pill :text="high" :type="high!=0?'negative':'inactive'" :circle="true" :tooltip="highText" :tooltipTitle="$gettext('High severity')"/>
    <Pill :text="critical" :type="critical!=0?'critical':'inactive'" :circle="true" :tooltip="criticalText" :tooltipTitle="$gettext('Critical severity')"/>
  </div>
</template>

<script setup>
  import { computed, toRefs, ref } from 'vue';

  import Pill from '@/components/UI/Pill';
  import Tooltip from '@/components/UI/Tooltip';

  const advisorytt = ref( null );
  const lowtt = ref( null );
  const mediumtt = ref( null );
  const hightt = ref( null );
  const criticaltt = ref( null );

  const props = defineProps( {
    type: {
      type: String,
      default: 'pills',
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    advisory: {
      type: Number,
      default: 0,
    },
    low: {
      type: Number,
      default: 0,
    },
    medium: {
      type: Number,
      default: 0,
    },
    high: {
      type: Number,
      default: 0,
    },
    critical: {
      type: Number,
      default: 0,
    },
  } );

  const { advisory, low, medium, high, critical, direction } = toRefs( props );

  let total = computed( () => advisory.value + low.value + medium.value + high.value + critical.value );
  
  if ( direction.value == 'vertical' ) {
    total = computed( () => Math.max( advisory.value, low.value, medium.value, high.value, critical.value ) );
  }

  const getPercent = severity => {
    switch( severity ) {
      case 'advisory': {
        return ( advisory.value / total.value ) * 100;
      }
      case 'low': {
        return ( low.value / total.value ) * 100;
      }
      case 'medium': {
        return ( medium.value / total.value ) * 100;
      }
      case 'high': {
        return ( high.value / total.value ) * 100;
      }
      case 'critical': {
        return ( critical.value / total.value ) * 100;
      }
    }
  };

  //TODO add translation
  const advisoryText = 'Issues that should be addressed to improve the user experience';
  const lowText = 'There may be a minor impact for some users';
  const mediumText = 'Some people may have difficulty using the product';
  const highText = 'Issues that cause severe difficulties for some groups of people';
  const criticalText = 'Issues that make the product unusable';
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .SeverityLine {
    width: 100%;
    max-width: 240px;
    height: 16px;
    background: $hugr-colours-input-surface;

    &._flex {
      display: flex;
      align-items: flex-end;
      column-gap: 8px;
      height: 100px !important;
      background: $hugr-colours-card-background;
    }

    &_Item {
      display: inline-block;
      min-height: 16px;
      position: relative;

      &._tall {
        min-height: 4px;
        height: 100%;
      }

      &_Inner {
        display: inline-block;
        width: 100%;
        height: 16px;
        position: relative;
        min-height: 4px;
        cursor: pointer;
        &:hover, &:focus, &._focus {
          transform: scale( 1.1 );
          z-index: 9;
        }

        &._tall {
          vertical-align: top;
          min-height: 4px;
          height: 100%;
        }
        &._advisory {
          background: $hugr-colours-pills-advisory;
        }
        &._low {
          background: $hugr-colours-pills-low;
        }
        &._medium {
          background: $hugr-colours-pills-medium;
        }
        &._high {
          background: $hugr-colours-pills-high;
        }
        &._critical {
          background: $hugr-colours-pills-critical;
        }
      }
    }
  }

  .SeverityPills {
    max-width: 240px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }

  ._darkMode .SeverityLine{
    &._flex {
      background: lighten( $hugr-colours-primary, 10% );
    }
  }
</style>
