<template>
  <div class="login-container">
    <div class="logo-container">
      <h1 class="logo"><span class="logo-main">Dig <span>Inclusion</span></span><span class="hugr">HUGR</span></h1>
    </div>
    <div class="login-form">
      <div class="inner" v-if="!mfamode">
        <h2 v-translate>Log in</h2>
        <form @submit.prevent="login">
          <span class="input">
            <label for="email">{{$gettext('Email address')}}</label>
            <input id="email" v-model="email"/>
          </span>
          <span class="lockWarn" v-if="locked&&email==lockedEmail">Account has been locked {{ lockedTime }}</span>

          <span class="input">
            <label for="password">{{$gettext('Password')}}</label>
            <input id="password" type="password" v-model="password"/>
          </span>

          <Button type="secondary" htype="submit">{{$gettext('Login')}}</Button>
          <GoogleLogin v-if="$hugrConfig.disableGoogle!='true'" class="googleLoginBtn" :callback="signInWithGoogle" prompt :buttonConfig="renderParams"/>
          <Button v-if="$hugrConfig.disableSignup!='true'" type="secondary" @click.prevent="$emit('changeForm');">{{$gettext('Sign up')}}</Button>
          <Button type="secondary" @click.prevent="$emit('forgotPassword');">{{$gettext('Forgot Password')}}</Button>
          <div class="stuck">
            {{$gettext('Need help?')}} <a href='mailto:hello@diginclusion.com?subject=Help%20accessing%20HUGR'>{{$gettext('Email us')}}</a>
          </div>
        </form>
      </div>
      <div class="inner" v-else-if="useMFAMethod=='authenticator'">
        <h2 v-translate>Second factor</h2>
        <form @submit.prevent="mfa">
          <span class="input">
            <label for="code">{{$gettext('Code')}}</label>
            <input id="code" v-model="authcode" autocomplete="false"/>
          </span>
          <div>
            <input type="checkbox" id="remember" v-model="rememberDevice">
            <label for="remember">{{$gettext('Trust this device')}}</label>
          </div>

          <Button type="secondary" htype="submit">{{$gettext('Login')}}</Button>

          <div v-if="canDoSecurityKey || canDoEmail">
            <h4 v-translate>Use another method?</h4>
            <Button v-if="canDoSecurityKey" size="small" @click="() => { currentMFAMethod = 'key'; this.getKeyChallenge() }">{{$gettext('Use security key')}}</Button>
            <Button v-if="canDoEmail" size="small" @click="() => { currentMFAMethod = 'email' }">{{$gettext('Use email')}}</Button>
          </div>

          <div class="stuck">
            {{$gettext('Need help?')}} <a href='mailto:hello@diginclusion.com?subject=Help%20accessing%20HUGR'>{{$gettext('Email us')}}</a>
          </div>
        </form>
      </div>
      <div class="inner" v-else-if="useMFAMethod=='email'">
        <h2 v-translate>Second factor</h2>
        <form @submit.prevent="emailMFA">
          <div v-if="emailRequested">
            <div>
              We've sent you an email. Either follow the magic link, or manually input the code below.
            </div>
            <span class="input">
              <label for="email-code">{{$gettext('Code')}}</label>
              <input id="email-code" v-model="emailMFACode" autocomplete="false"/>
            </span>
            <br>
            <div>
              <input type="checkbox" id="remember" v-model="rememberDevice">
              <label for="remember">{{$gettext('Trust this device')}}</label>
            </div>
            <br>
            <div>
              Didn't receive an email? Stay on this page and request a new one in a few minutes.
            </div>
            <br>
          </div>
          <div v-else>
            Request a 2FA email by clicking the button below.
          </div>
          <Button type="secondary" htype="submit" v-if="emailRequested">{{$gettext('Login')}}</Button>
          <Button type="secondary" v-if="!emailRequestedRecently" :disabled="emailRequestedRecently" @click.prevent="() => { this.requestMFAEmail() }">{{emailRequestedRecently ? $gettext('Please wait...') : $gettext('Request Email')}}</Button>

          <div v-if="canDoSecurityKey || canDoAuthenticator">
            <h4 v-translate>Use another method?</h4>
            <Button v-if="canDoAuthenticator" size="small" @click="() => { currentMFAMethod = 'authenticator' }">{{$gettext('Use authenticator')}}</Button>
            <Button v-if="canDoSecurityKey" size="small" @click="() => { currentMFAMethod = 'key'; this.getKeyChallenge() }">{{$gettext('Use security key')}}</Button>
          </div>

          <div class="stuck">
            {{$gettext('Need help?')}} <a href='mailto:hello@diginclusion.com?subject=Help%20accessing%20HUGR'>{{$gettext('Email us')}}</a>
          </div>
        </form>
      </div>
      <div class="inner" v-else-if="useMFAMethod=='key'">
        <h2 v-translate>Second factor</h2>
        <p v-if="awaitingKey" v-translate>Plug in your security key and click the button</p>
        <Button v-else @click="startSecurityKey">{{$gettext('Use security key')}}</Button>
        <div>
          <input type="checkbox" id="remember" v-model="rememberDevice">
          <label for="remember">{{$gettext('Trust this device')}}</label>
        </div>

        <div v-if="canDoAuthenticator || canDoEmail">
          <h4 v-translate>Use another method?</h4>
          <Button v-if="canDoAuthenticator" size="small" @click="() => { currentMFAMethod = 'authenticator' }">{{$gettext('Use authenticator')}}</Button>
          <Button v-if="canDoEmail" size="small" @click="() => { currentMFAMethod = 'email' }">{{$gettext('Use email')}}</Button>
        </div>

        <div class="stuck">
            {{$gettext('Need help? ')}}<a href='mailto:hello@diginclusion.com?subject=Help%20accessing%20HUGR'>{{$gettext('Email us')}}</a>
          </div>
      </div>
      <div class="inner" v-else>
        <h2 v-translate>Second factor</h2>
        <p v-translate>Something went wrong, no valid 2fa methods found</p>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import md5 from 'md5';
import { generate } from '../lib/dynodwr';

export default {
  name: 'LoginView',
  mounted() {
    this.$apollo.query( {
      query: gql`
        query {
          ip: getIP
        }
      `,
    } ).then( res => {
      generate( true, res.data.ip ).then( fingerprint => {
        this.fingerprint = fingerprint;
      } );
    } );

    if ( this.mfaSuppliedMagicCode != '' ) {
      this.emailMFACode = this.mfaSuppliedMagicCode;
      this.emailRequested = true;
      this.mfamode = true;
      this.currentMFAMethod = 'email';
    }
    if ( this.mfaSuppliedID != '' ) this.mfaID = this.mfaSuppliedID;
  },
  data() {
    return {
      // params: {
      //   client_id: "540616237993-tdae2nveoe2illukbkc7hfgtusg8u9a5.apps.googleusercontent.com"
      // },
      renderParams: {
        width: 300,
        height: 52,
        longtitle: true,
        theme: 'dark',
      },
      awaitingKey: false,
      email: '',
      password: '',
      authcode: '',
      emailMFACode: '',
      emailRequested: false,
      emailRequestedRecently: false,
      mfamode: false,
      mfaToken: '',
      mfaID: '',
      mfaMethods: [],
      currentMFAMethod: false,
      rememberDevice: false,
      fingerprint: false,
      ip: '',

      locked: false,
      lockedEmail: '',
      lockedTime: 'temporarily',
    };
  },
  watch: {

  },
  methods: {
    startSecurityKey() {
      this.awaitingKey = true;
      this.getKeyChallenge();
    },
    signInWithGoogle( response ) {
      const { credential } = response;

      this.$apollo.mutate( {
        mutation: gql`mutation ($credential: String!){
          loginWithGoogle(credential: $credential){
            success,
            accessToken,
            refreshToken,
            user {
              name,
              displayname,
              email,
              aclevel
            },
            extraInfo
          }
        }`,
        variables: {
          credential,
        },
      } ).then( res => {
        const login = res.data.loginWithGoogle;

        if( login.success ) {
          this.$session.start();
          this.$session.set( 'user', {
            email: login.user.email,
            name: login.user.name,
            displayname: login.user.displayname,
            aclevel: login.user.aclevel,
            accessToken: login.accessToken,
            refreshToken: login.refreshToken,
          } );
          this.$session.set( 'email', login.user.email );

          this.$session.set( 'jwt', login.accessToken );
          localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

          this.$session.set( 'jwt-refresh', login.refreshToken );
          localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

          this.setUser( {
            email: login.user.email,
            name: login.user.name,
            displayname: login.user.displayname,
            aclevel: login.user.aclevel,
            accessToken: login.accessToken,
            refreshToken: login.refreshToken,
          } );
          this.$emit( 'success', true );
        }

        if ( login.extraInfo !== undefined ) {
          if ( login.success ) {
            this.$alerts.success( "Success", login.extraInfo );
          } else {
            this.$alerts.error( "Error", login.extraInfo );
          }
        }
      } ).catch( () => {
        this.$alerts.error( `Login failed`, `Something went wrong with the Google login. Try again later or contact us if the problem persists.` );
      } );
    },
    login() {
      // this.$log.debug('Got Credentials', this.email, this.password);
      if ( this.email == '' || this.password == '' ) {
        this.$alerts.error( `Login failed`, `Please fill out both the password and email inputs.` );
      } else {
      this.$apollo.mutate( {
        mutation: gql`mutation ($email: String!, $password: String!, $fingerprint: String) {
          login(email: $email, password: $password, fingerprint: $fingerprint) {
            success,
            reason,
            lockTime,
            accessToken,
            refreshToken,
            needsmfa,
            user {
              _id,
              name,
              email,
              aclevel,
              displayname,
              mfaMethods {
                type,
                primary,
                active,
                confirmed
              }
            }
          }
        }`,
        variables: {
          email: this.email,
          password: md5( `${this.password}:alienFORGERY` ),
          fingerprint: this.fingerprint,
        },
      } ).then( res => {
        const { login } = res.data;
        if( login.success && login.needsmfa ) {
          this.mfamode = true;
          this.mfaToken = login.accessToken;
          this.mfaMethods = login.user.mfaMethods;
          this.mfaID = login.user._id;
          // setTimeout(() => {
          //   if(this.useMFAMethod=='key') {
          //     this.getKeyChallenge();
          //   }
          // }, 100)
        } else if( login.success ) {
          this.setCredentials( login );
        } else {
          switch( login.reason ) {
            case 'ACCOUNT_LOCKED_INIT': {
              let timeStr = 'temporarily';
              if( parseInt( login.lockTime ) >= 3600 ) {
                timeStr = `for ${parseInt( login.lockTime ) / 3600} hours`;
              } else {
                timeStr = `for ${parseInt( login.lockTime ) / 60} minutes`;
              }
              this.$alerts.error( `Incorrect login!`, `It looks like you are using the wrong credentials. To protect your account login has been locked ${timeStr}.` );
              this.locked = true;
              this.lockedEmail = this.email;
              this.lockedTime = timeStr;

              break;
            }
            case 'ACCOUNT_LOCKED': {
              this.$alerts.error( `Account locked`, `It looks like your account is locked, please try again later.` );

              break;
            }
            case 'ACCOUNT_DISABLED': {
              this.$alerts.error( `Account disabled`, `It looks like your account is disabled, please contact hello@diginclusion.com if you think this is an error.` );

              break;
            }
            case 'BAD_LOGIN':
            case 'ACCOUNT_INVALID':
            default: {
              localStorage.setItem( 'HUGR_INCORRECT_LOGIN_EMAIL', this.email );
              this.$alerts.error( `Incorrect login!`, `It looks like you are using the wrong credentials. Please try again or reset your password. Your account will temporarily lock after 5 failed attempts.` );
            }
          }
        }
      } ).catch( err => {
        this.$catchError( err );
        this.$alerts.error( `Login failed`, `We think this is a problem our end.` );
      } );
      }
    },
    requestMFAEmail() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          result: requestMFAEmail(id: $id) {
            success,
            reason
          }
        }`,
        variables: {
          id: this.mfaSuppliedID ? this.mfaSuppliedID : this.mfaID,
        },
      } ).then( res => {
        const { result } = res.data;
        if( result.success ) {
          this.$alerts.success( `Email sent!`, `Check your email for a link and code!` );
          this.emailRequested = true;
          this.emailRequestedRecently = true;
          setTimeout( () => {
            this.emailRequestedRecently = false;
          }, 120000 );
        } else {
          this.$alerts.error( `Email not sent!`, result.reason );
        }
      } ).catch( () => {
        this.$alerts.error( `Email request failed`, `We think this is a problem on our end.` );
      } );
    },
    emailMFA() {
      if ( this.emailMFACode == '' ) {
        this.$alerts.error( `Invalid input`, `Please fill out the MFA code input.` );
      } else {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!, $magicCode: String!, $fingerprint: String) {
          login: emailMFALogin(id: $id, magicCode: $magicCode, fingerprint: $fingerprint) {
            success,
            accessToken,
            refreshToken,
            user {
              name,
              email,
              aclevel
            }
          }
        }`,
        variables: {
          id: this.mfaSuppliedID ? this.mfaSuppliedID : this.mfaID,
          magicCode: this.emailMFACode,
          fingerprint: this.rememberDevice && this.fingerprint ? this.fingerprint : null,
        },
      } ).then( res => {
        const { login } = res.data;
        if( login.success ) {
          this.setCredentials( login );
        } else {
          this.$alerts.error( `2FA code issue!`, `Invalid or incorrect code.` );
        }
      } ).catch( () => {
        this.$alerts.error( `Login failed`, `We think this is a problem on our end.` );
      } );
      }
    },
    mfa() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($token: String, $totp: String, $fingerprint: String) {
          login: mfaLogin(token: $token, totp: $totp, fingerprint: $fingerprint) {
            success,
            accessToken,
            refreshToken,
            user {
              name,
              email,
              aclevel
            }
          }
        }`,
        variables: {
          token: this.mfaToken,
          totp: this.authcode,
          fingerprint: this.rememberDevice && this.fingerprint ? this.fingerprint : null,
        },
      } ).then( res => {
        const { login } = res.data;
        if( login.success ) {
          this.setCredentials( login );
        } else {
          this.$alerts.error( `2FA code incorrect!`, `It looks like you are using the wrong code. Please try again` );
        }
      } ).catch( () => {
        this.$alerts.error( `Login failed`, `We think this is a problem our end.` );
      } );
    },
    setCredentials( login ) {
      this.$session.start();
      this.$session.set( 'user', {
        email: login.user.email,
        name: login.user.name,
        displayname: login.user.displayname,
        aclevel: login.user.aclevel,
        accessToken: login.accessToken,
        refreshToken: login.refreshToken,
      } );
      this.$session.set( 'email', login.user.email );

      this.$session.set( 'jwt', login.accessToken );
      localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

      this.$session.set( 'jwt-refresh', login.refreshToken );
      localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

      this.setUser( {
        email: login.user.email,
        name: login.user.name,
        displayname: login.user.displayname,
        aclevel: login.user.aclevel,
        accessToken: login.accessToken,
        refreshToken: login.refreshToken,
      } );
      this.$emit( 'success', true );

      // this.$router.push('/dashboard')
    },
    getKeyChallenge() {
      const toArrayBuffer = str => {
        const arr = str.split( ',' );
        const ab = new ArrayBuffer( arr.length );
        const view = new Uint8Array( ab );
        for ( let i = 0; i < arr.length; ++i ) {
            view[i] = parseInt( arr[i] );
        }

        return ab;
      };

      this.$apollo.query( {
        query: gql`query SecurityKeyChallenge($token: String!) {
          challenge: SecurityKeyChallenge(token: $token) {
            challenge,
            credentials {
              _id,
              handle
            }
          }
        }`,
        variables: {
          token: this.mfaToken,
        },
      } ).then( res => {
        const { challenge } = res.data;

        const allowCredentials = [];
        for( const credential of challenge.credentials ) {
          allowCredentials.push( {
            id: toArrayBuffer( credential.handle ),//Uint8Array.from(credential.handle, c => c.charCodeAt(0)),
            type: 'public-key',
            transports: [ 'usb', 'ble', 'nfc' ],
          } );
        }

        const publicKeyCredentialRequestOptions = {
            challenge: Uint8Array.from( challenge.challenge, c => c.charCodeAt( 0 ) ),
            allowCredentials,
            timeout: 60000,
        };

        navigator.credentials.get( {
            publicKey: publicKeyCredentialRequestOptions,
        } ).then( credential => {
          this.sendWebAuthNCredential( credential );
        } ).catch( () => {
          //TODO error
          this.awaitingKey = true;
        } );
      } ).catch( () => {
        //TODO error
        this.awaitingKey = true;
      } );
    },
    sendWebAuthNCredential( credential ) {
      const response = {
        authenticatorData: new Uint8Array( credential.response.authenticatorData ).toString( 'utf8' ),
        clientDataJSON: new Uint8Array( credential.response.clientDataJSON ).toString( 'utf8' ),
        signature: new Uint8Array( credential.response.signature ).toString( 'utf8' ),
        // userHandle: new Uint8Array(credential.response.userHandle).toString('utf8'), //only for resident keys, not needed
      };
      this.$apollo.mutate( {
        mutation: gql`mutation ($token: String, $id: String, $response: AuthenticatorAssertionResponse, $fingerprint: String) {
          login: webAuthNLogin(token: $token, id: $id, response: $response, fingerprint: $fingerprint) {
            success,
            accessToken,
            refreshToken,
            user {
              name,
              displayname,
              email,
              aclevel
            }
          }
        }`,
        variables: {
          token: this.mfaToken,
          id: credential.id,
          response,
          fingerprint: this.rememberDevice && this.fingerprint ? this.fingerprint : null,
        },
      } ).then( res => {
        const { login } = res.data;
        if( login.success ) {
          this.setCredentials( login );
        } else {
          //TODO error
        }
      } ).catch( () => {
        //TODO ERROR
      } );
    },
    ...mapActions( [ 'setUser' ] ),
  },
  computed: {
    ...mapState( [ 'user' ] ),
    useMFAMethod() {
      return this.currentMFAMethod || this.primaryMFAMethod;
    },
    primaryMFAMethod() {
      if( this.mfaMethods ) {
        for( const method of this.mfaMethods ) {
          if( method.primary ) {
            return method.type;
          }
        }

        return this.mfaMethods[0].type;
      }

      return false;
    },
    canDoSecurityKey() {
      if( this.mfaMethods ) {
        for( const method of this.mfaMethods ) {
          if( method.active && method.confirmed && method.type == 'key' ) {
            return true;
          }
        }
      }

      return false;
    },
    canDoEmail() {
      if( this.mfaMethods ) {
        for( const method of this.mfaMethods ) {
          if( method.active && method.confirmed && method.type == 'email' ) {
            return true;
          }
        }
      }

      return false;
    },
    canDoAuthenticator() {
      if( this.mfaMethods ) {
        for( const method of this.mfaMethods ) {
          if( method.active && method.confirmed && method.type == 'authenticator' ) {
            return true;
          }
        }
      }

      return false;
    },
  },
  components: {
    // GoogleLogin,
  },
  props: [ 'mfaSuppliedID', 'mfaSuppliedMagicCode' ],
};
</script>

<style lang="scss" scoped>
  .stuck {
    margin-top: 10px;
  }
  div.login-container {
    background: #262E37;
    min-height: calc( 100vh - 50px );
    padding-bottom: 50px;
    color: #000;
    .logo-container {
      margin: auto;
      width: 275px;
      display: block;
      padding: 100px 0 50px;
      h1.logo {
        font-size: 1.25em;
        line-height: 1;
        span.logo-main {
          font-family: 'fs-me-bold', sans-serif;
          color: #FFF;
          border-bottom: 0;
          background-image: url(../assets/images/logo-marque-primary.png);
          background-repeat: no-repeat;
          background-position: left center;
          background-color: transparent;
          background-size: 50px;
          padding-left: 55px;
          height: 46px;
          padding-top: 10px;
          display: inline-block;
          span {
            font-family: 'fs-me-regular', sans-serif;
            font-size: 1em;
            font-weight: 300;
            display:block;
          }
        }
        .hugr {
          color: #FFF;
          border-left: 1px solid #dce4ee;
          vertical-align: top;
          padding: 25px 15px;
          font-size: 25pt;
          font-weight: normal;
          margin-left: 20px;
          top: 15px;
          position: relative;
        }
      }
    }
    .login-form {
      background:#dce4ee;
      max-width: 300px;
      margin: auto;
      padding: 20px;
      div.inner {
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 16pt;
          text-align: center;
          color: #000;
        }
        button {
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .lockWarn {
      font-size: 0.8em;
    }
  }

  .googleLoginBtn {
    width: 100%;
    margin-top: 25px;
    iframe {
      width: 100%
    }
  }
</style>
