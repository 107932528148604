<template>
  <template v-if="!loading">
    <template v-if="ssResult">
      <div class="Checks">
        <h2 class="Checks_Heading">Checks</h2>
        <ProgressBar class="Checks_Progress" id="pageprogress" label="Checks Conducted: " :value="ssResult.page.rows.filter( row => getCellStatus(row.columns[0].cell).toLowerCase() != 'empty' ).length" :total="ssResult.page.rows.length-1" type="count"/>

        <TabList class="Checks_TabList" :tabs="tabs"/>
        <div id="tabpane" class="Checks_Pane">
          <template v-if="tab!='empty'||progress<100">
            <template v-for="row in ssResult.page.rows" :key="row.template.ref">
              <h3 v-if="row.newSection && ssResult.page.rows.filter( r => r.template.section == row.template.section && ( getCellStatus(r.columns[0].cell).toLowerCase() == tab || ( tab == 'empty' && r.columns[0].cell.keepInTodo ) || ( tab == 'empty' && r.columns[0].cell.hasUnassoc ) || ( tab == 'empty' && r.columns[0].cell.hasClosed ) || ( tab == 'empty' && r.columns[0].cell.hasItemUnassoc ) ) ).length" class="Checks_Pane_SectionTitle"><span>{{ row.template.section }}</span></h3>

              <ColumnChecksCell v-if="row.template.header != 'Notes' && ( getCellStatus(row.columns[0].cell).toLowerCase() == tab || ( tab == 'empty' && row.columns[0].cell.keepInTodo ) || ( tab == 'empty' && row.columns[0].cell.hasUnassoc ) || ( tab == 'empty' && row.columns[0].cell.hasClosed ) || ( tab == 'empty' && row.columns[0].cell.hasItemUnassoc ) )"
                :template="row.template"
                :cell="row.columns[0].cell"
                :tab="tab" @saved="dataChanged()"
                :editable="editable"
                :title="row.newSection?row.template.section:''"
                @goToIssue="goToIssue"
                @issue="openModal" @guidance="( details ) => emit( 'guidance', details )"
                @close="manageClose" />
            </template>
          </template>
          <div v-else class="Checks_Pane_Complete">
            All checks complete
          </div>
        </div>
      </div>
      <IssueInstanceModal ref="modal" v-bind:report="reportVersion" @success="newInstance"/>
    </template>
    <template v-else>
      <div class="AddSheet">
        <p>To start testing you need to setup a checklist to track progress and locate issues. We have a number of pre-made templates to help you get to testing without having to remember the exact WCAG references.</p>
        <div class="AddSheet_Options" v-if="templates">
          <div class="AddSheet_Options_Option" v-for="(template, tid) in templates" :key="tid">
            <h3>{{ template }}</h3>
            <p>{{ templateDescs[tid] }}</p>
            <Button size="small" type="primary" @click="useSheet(tid)">{{$gettext('Use this one')}}</Button>
          </div>
        </div>

        <!-- <template v-if="previousSheetsFiltered.length">
          <p v-translate>or import a previous page</p>

          <div class="AddSheet_Options">
            <template v-for="page in previousSheetsFiltered" :key="page._id">
              <div class="AddSheet_Options_Option">
                <h3>{{ page.title }} {{$gettext('from version')}} {{ page.reportVersion.version }}</h3>
                <p>{{$gettext('Template:')}} {{ page.template.name }}</p>
                <Button size="small" type="primary" @click="importSheet(page._id)">{{$gettext('Import this one')}}</Button>
              </div>
            </template>
          </div>
        </template> -->
      </div>
    </template>
  </template>
  <Loader v-else />
</template>

<script setup>
  import ProgressBar from '../components/UI/ProgressBar.vue';
  import ColumnChecksCell from './ColumnChecksCell.vue';
  import IssueInstanceModal from '@/modals/IssueInstance/Create';
  
  import { toRefs, ref, computed } from 'vue';
  import { useQuery, useMutation, useSubscription } from "@vue/apollo-composable";
  import { useStore } from "vuex";
  import gql from "graphql-tag";

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );

  const props = defineProps( {
    reportVersion: String,
    column: String,
    type: String,
    progress: Number,

    editable: {
      type: Boolean,
      default: () => true,
    },
  } );

  const { reportVersion, column, type, progress, editable } = toRefs( props );

  const modal = ref( null );

  const tab = ref( 'empty' );
  const subscriptionVariables = ref( {
    spreadsheet: null,
  } );

  const { result: ssResult, onResult, loading, refetch } = useQuery( gql`
    query SpreadsheetPage($reportVersion: ObjectID!, $page: Int!, $column: ObjectID) {
      page: SpreadsheetPage(reportVersion: $reportVersion, page: $page, column: $column) {
        _id
        number,
        title,
        userJourney {
          _id
          title
        }
        deleted,
        rows {
          newSection
          template {
            _id
            section
            ref
            header
            details
          }
          columns {
            id,
            header,
            type,
            page {
              _id
              host
              path
            }

            cell {
              _id
              row
              column
              type
              items {
                done,
                text,
                instance,
              }
              hasUnassoc #issue exists but not in cell
              hasItemUnassoc #item in cell issues doesn't have page assoc
              hasClosed
            }
          }
        }
      }
    }
  `,
  {
    reportVersion,
    page: 0,
    column,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  const emit = defineEmits( [ 'changed', 'guidance' ] );

  const dataChanged = () => {
    emit( 'changed', true );
  };

  const { onResult: onNewData } = useSubscription( gql`
    subscription SpreadsheetCellChanged($spreadsheet: String!) {
      cell: SpreadsheetCellChanged(spreadsheet: $spreadsheet) {
        _id,
        row,
        column,
        type,
        sheet
        items {
          done,
          text,
          instance,
        }
      }
    }
  `,
  subscriptionVariables );

  onNewData( ( { data } ) => {
    if( data.cell.column === column.value ) {
      for( let row = 0; row <= ( ssResult.value.page.rows.length - 1 ); row++ ) {
        if( ssResult.value.page.rows[row].template._id === data.cell.row && ssResult.value.page.rows[row].columns[0].id === data.cell.column ) {
          ssResult.value.page.rows[row].columns[0].cell.items = data.cell.items;
          dataChanged();
        }
      }
    }
  } );

  onResult( () => {
    subscriptionVariables.value = {
      spreadsheet: ssResult.value.page._id,
    };
  } );

  const getCellStatus = cell => {
    const statoos = [ 'fail', 'pass', 'advisory', 'na', 'not applicable' ];
    if( cell.items.length === 0 ) return 'empty';
    if( statoos.indexOf( cell.items[0].text.toLowerCase() ) >= 0 ) return cell.items[0].text;

    return 'empty';
  };

  const countCells = tabT => {
    let statoos = ssResult.value.page.rows.filter( r => r.template.header != 'Notes' );
    statoos = statoos.map( r => getCellStatus( r.columns[0].cell ) );
    
    return statoos.filter( s => s.toLowerCase() == tabT ).length;
  };

  const tabs = ref( [
    {
      text: "To do",
      controls: "tabpane",
      action: () => { tab.value = 'empty' },
      pill: () => [ countCells( 'empty' ) ],
    },
    {
      text: "Passed",
      controls: "tabpane",
      action: () => { tab.value = 'pass' },
      pill: () => [ countCells( 'pass' ) ],
    },
    {
      text: "Failed",
      controls: "tabpane",
      action: () => { tab.value = 'fail' },
      pill: () => [ countCells( 'fail' ) ],
    },
    {
      text: "Advisory",
      controls: "tabpane",
      action: () => { tab.value = 'advisory' },
      pill: () => [ countCells( 'advisory' ) ],
    },
  ] );
  if( !hasPermission.value( 'SimpleMode', 'NoNotApplicable' ) ) {
    tabs.value.push( {
      text: "Not applicable",
      controls: "tabpane",
      action: () => { tab.value = 'not applicable' },
      pill: () => [ countCells( 'not applicable' ) ],
    } );
  }

  const modalCell = ref( null );
  const newInstance = ref( null );

  const manageClose = ( moveFocus, container ) => {
    if( !moveFocus ) return;

    let sibling = container.value.nextElementSibling;

    if( !sibling.classList.contains( 'ChecksCell' ) ) sibling = sibling.nextElementSibling;
    if( !sibling.classList.contains( 'ChecksCell' ) ) sibling = sibling.nextElementSibling;
    if( !sibling.classList.contains( 'ChecksCell' ) ) return;

    sibling.focus();
  };

  const openModal = ( row, func ) => {
    modal.value.show();
    modal.value.setRow( row );
    if( type.value == 'page' ) {
      modal.value.presetPage( column.value );
    } else if( type.value == 'component' ) {
      modal.value.presetComponent( column.value );
    }
    modalCell.value = row;
    newInstance.value = func;
  };

  const doTemplatesQuery = computed( () => !loading && !ssResult.value );

  const { result: templateResult, onResult: onTemplates, loading: templateLoading, refetch: refetchTemplates } = useQuery( gql`
    {
      templates: SpreadsheetTemplates {
        _id,
        name,
        description,
        published,
        promoted
      }
    }
  `,
  {
    fetchPolicy: 'no-cache',
    enabled: doTemplatesQuery,
  } );

  const templates = ref( {} );
  const newTemplate = ref( null );
  const templateDescs = ref( {} );

  onTemplates( ( { data } ) => {
    if( !data ) return;
    
    const tmpTemplates = {};
    let promoted;
    for( const template of data.templates ) {
        if( template.published ) {
          templateDescs.value[template._id] = template.description;
          if( template.promoted ) {
            promoted = template;
          } else {
            tmpTemplates[template._id] = template.name;
          }
        }
      }

      newTemplate.value = promoted._id;
      const p = {};
      // eslint-disable-next-line no-unused-expressions
      p[promoted._id] = `${promoted.name} (recommended)`;

      templates.value = {
        ...p,
        ...tmpTemplates,
      };
  } );

  const { mutate: addSpreadsheetPage } = useMutation(
    gql`
    mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
      addSpreadsheetPage(page: $page) {
        _id
      }
    }
  ` );

  const useSheet = sheet => {
    const page = {
      reportVersion: reportVersion.value,
      template: sheet,
      title: `Sheet 1`,
      number: 0,
    };

    addSpreadsheetPage( {
      page,
    } ).then( () => {
      refetch();
    } );
  };

  const goToIssue = identifier => {
    emit( 'goToIssue', identifier );
  };

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  
  .Checks {
    position: relative;
    // border: 1px solid $hugr-colours-grey;
    background: lighten( $hugr-colours-grey, 15% );
    padding: 16px;

    &_Heading {
      margin-top: 0;
    }
    &_Progress {
      position: absolute;
      right: 16px;
      top: 21px;
    }

    &_TabList {
      padding-bottom: 8px !important;
    }

    &_Pane {
      height: calc( 100vh - 358px );
      overflow-y: auto;
      padding-right: 6px;

      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        // background: #262d3650;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: rgba(0, 0, 0, 0.2);
        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      &_SectionTitle {
        display: block;
        width: 100%;
        > span {
          display: inline-block;
          font-size: 0.9em;
          font-weight: 600;
          color: $hugr-colours-primary;
          background: lighten( $hugr-colours-grey, 15% );
        }
      }

      &_Complete {
        text-align: center;
        font-size: 1.6em;
        color: $hugr-colours-green;
        margin: 40% 0;
      }
      
    }
  }

  .AddSheet {
    padding: 32px;
    &_Button {
      float: right;
      margin-top: 20px;
    }

    &_Options {
      border: 1px solid $hugr-colours-grey;
      border-radius: 10px;
      &_Option {
        border-bottom: 1px solid $hugr-colours-grey;
        padding: 8px;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        h3 {
          margin: 0;
        }
        p {
          margin-bottom: 0;
        }
        button {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }
    }
  }

  ._darkMode .Checks {
    border: 1px solid $hugr-colours-grey;
    background: darken( $hugr-colours-primary, 10% );

    &_Pane {
      &::-webkit-scrollbar-track {
        border: 4px solid rgba(255, 255, 255, 0);
        background-color: rgba(255, 255, 255, 0.2);
      }
      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(255, 255, 255, 0);
        background-color: rgba(255, 255, 255, 0.3);
        &:hover {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }
      &_SectionTitle {
        > span {
          color: #FFF;
          background: darken( $hugr-colours-primary, 10% );
        }
        &:after {
          border-bottom: 1px solid $hugr-colours-grey;
        }
      }
    }
  }
</style>
