<template>
  <div v-if="isShow&&ready" class="Modal" @mouseup="mouseUp">
    <div v-if="stageClosure" class="Modal_StageClosure" aria-live="polite"><p>Click outside or press escape again to close modal</p></div>
    <div  ref="modalwindow"
          class="Modal_Inner"
          tabindex="-1"
          role="dialog"
          :aria-labelledby="uid+'-modal-title'"
          aria-modal="true"
          @mousedown="mouseDown"
          v-click-away="clickAway">
      <div class="Modal_Inner_Head">
        <Button class="Modal_Inner_Head_Close" type="icon" :icon="['solid', 'times']" @click="() => { this.reset(); $emit('close') }">close</Button>
      </div>
      <div class="Modal_Inner_SubHead">
        <StepProgress v-if="stepsInHeader" :currentStep="currentStep" :totalSteps="totalSteps"/>
        <h2 :id="uid+'-modal-title'" class="Modal_Inner_SubHead_Title" :title="title">{{title}}</h2>
        <h3 v-if="subtitle" class="Modal_Inner_SubHead_SubTitle">{{ subtitle }}</h3>
      </div>
      <form @submit.prevent.stop="$emit( 'submit' )">
        <div :class="['Modal_Inner_Body', fix?'_fix':'', padBody?'_pad':'']">
          <slot></slot>
        </div>
        <div v-if="showActions" class="Modal_Inner_Actions">
          <div class="Modal_Inner_Actions_Left">
            <Button type="primary" size="small" @click="$emit( 'close' )">{{$gettext('Cancel')}}</Button>
          </div>
          <div class="Modal_Inner_Actions_Right" v-if="stepsInHeader&&!completed">
            <Button v-if="currentStep > 1 && !noBack" size="small" type="transparent" @click="$emit('back')">{{ backText }}</Button>
            
            <span v-if="loading">{{ loadingText }}</span>
            <Button v-else-if="canContinue&&currentStep!=totalSteps" size="small" type="secondary" @click="$emit('continue')">{{ continueText }}</Button>
            <Button v-else-if="canContinue&&currentStep==totalSteps" size="small" htype="submit" type="blue">{{ submitText }}</Button>
            <span v-else>{{ emptyText }}</span>
          </div>
          <div class="Modal_Inner_Actions_Right" v-else-if="!noSubmit">
            <span v-if="loading">{{ loadingText }}</span>
            <Button v-else size="small" htype="submit" type="secondary">{{ submitText }}</Button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StepProgress from './StepProgress.vue';

const TIMEOUT = 100;
const TAB_KEY = 9;

export default {
  name: 'UIModal',
  data() {
    return {
      uid: this.$uuid.generate(),

      isShow: false,
      activatingButton: false,
      originatedInModal: false,
      stageClosure: false,
    };
  },
  methods: {
    show() {
      this.isShow = true;
      this.stageClosure = false;
      this.activatingButton = document.activeElement;
      setTimeout( () => {
        this.$refs.modalwindow.focus();
      }, TIMEOUT );
      document.addEventListener( 'keyup', this.tab );
      document.addEventListener( 'keyup', this.esc );

      // Stop scrolling body when modal opens.
      document.querySelector( 'body' ).style.overflow = 'hidden';
    },
    close() {
      this.isShow = false;
      document.removeEventListener( 'keyup', this.tab );
      document.removeEventListener( 'keyup', this.esc );

      if( this.activatingButton && typeof this.activatingButton != 'undefined' && "focus" in this.activatingButton && typeof this.activatingButton.focus == 'function' ) {
        try {
          this.activatingButton.focus();
          this.activatingButton = false;
        } catch( e ) {
          console.log( "Couldn't focus activating button" );
        }
      }

      document.querySelector( 'body' ).style.overflow = 'auto';
    },
    esc( e ) {
      if( e.key == "Escape" ) {
        if( this.stageClosure ) {
          this.reset();
        } else {
          this.stageClosure = true;
        }
      }
    },
    mouseDown() {
      this.originatedInModal = true;
      this.stageClosure = false;
    },
    mouseUp() {
      setTimeout( () => {
        this.originatedInModal = false;
      }, TIMEOUT );
    },
    reset() {
      this.close();
    },
    clickAway() {
      if( this.originatedInModal ) return;

      if( this.stageClosure ) {
        this.reset();
      } else {
        this.stageClosure = true;
      }
    },
    tab( e ) { //IT'S A TARP
      if( e.keyCode == TAB_KEY ) {
        if( this.$refs.modalwindow != null && !this.$refs.modalwindow.querySelectorAll( ":focus" ).length && document.activeElement != this.$refs.modalwindow ) {
          this.$refs.modalwindow.focus();
        }
      }
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    fix: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    stepsInHeader: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 3,
    },
    padBody: {
      type: Boolean,
      default: true,
    },

    showActions: {
      type: Boolean,
      defaut: false,
    },
    noSubmit: {
      type: Boolean,
      default: false,
    },
    noBack: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
    continueText: {
      type: String,
      default: 'Continue',
    },
    canContinue: {
      type: Boolean,
      default: false,
    },
    backText: {
      type: String,
      default: 'Back',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    emptyText: {
      type: String,
      default: 'Nothing to do.',
    },
    completed: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    StepProgress,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';

  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    background: rgba(38, 46, 55, 0.5);

    &_StageClosure {
      position: absolute;
      width: 100%;
      top: 24px;
      p {
        margin: auto;
        width: 35%;
        max-width: 400px;
        text-align: center;
        background: white;
        padding: 8px;
        border-radius: 5px;
      }
    }

    &_Inner {
      z-index: 9999999;
      min-width: 500px;
      width: 50%;
      margin: auto;
      background: white;

      // border: 1px solid #c6cdd7;
      border-radius: 3px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0px 0px 8px -4px $hugr-colours-primary;

      &_Head {
        background: $dig-blue;
        height: 32px;

        &_Close {
          position: absolute !important;
          right: 0px;
          top: -1px;
          color: #FFF !important;
        }
      }
      &_SubHead {
        width: 100%;
        overflow-y: auto;
        max-height: 75vh;
        padding: 8px 16px;
        &_Title {
          font-weight: bold;
          margin: 0;
          max-width: 96%;
        }
        &_SubTitle {
          color: lighten( $hugr-colours-primary, 20% );
          font-size: 0.9em;
          font-weight: bold;
          margin-top: -4px;
        }
      }
      &_Body {
        width: 100%;
        overflow-y: auto;
        max-height: 75vh;

        &._fix {
          height: 75vh;
        }

        &._pad {
          padding: 16px;
          box-sizing: border-box;
        }

        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          // background: #262d3650;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: rgba(0, 0, 0, 0.2);
          &:hover {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }

        // > form {
        //   padding: 16px;
        //   position: relative;
        //   box-sizing: border-box;
        // }
      }

      &_Actions {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        background: lighten( $hugr-colours-grey, 10% ); //#EBEDF0;

        &_Left{

        }

        &_Right {
          > span {
            margin-top: 8px;
            display: inline-block;
          }
        }
      }
    }
  }

  ._darkMode .Modal {
    &_StageClosure {
      p {
        background: black;
      }
    }
    &_Inner {
      border: 1px solid $dig-blue;
      &_Close {
        color: white !important;
      }
      background: $hugr-colours-primary;
      &_SubHead {
        &_SubTitle {
          color: darken( $hugr-colours-grey, 10% ) !important;
        }
      }
      &_Body {
        &::-webkit-scrollbar-track {
          border: 4px solid rgba(255, 255, 255, 0);
          background-color: rgba(255, 255, 255, 0.2);
        }
        &::-webkit-scrollbar-thumb {
          border: 4px solid rgba(255, 255, 255, 0);
          background-color: rgba(255, 255, 255, 0.3);
          &:hover {
            background-color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }

  @media (max-width: 525px) {
    .Modal {
      &_Inner {
        margin: unset;
        transform: unset;
        max-height: unset;
        min-width: unset;

        width: 100%;
        border: none;
        top: 66px;
        height: calc( 100vh - 66px );
        box-sizing: border-box;
      }
    }
  }
</style>
