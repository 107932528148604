<template>
  <div class="ChecksCell" tabindex="-1" ref="container">
    <div class="ChecksCell_Info">
      <h4 :class="['ChecksCell_Info_Header', cellStatus=='empty'&&!cellOpen&&!cell.hasUnassoc&&editable?`_unstarted`:'']">
        <span :class="['ChecksCell_Info_Header_CheckTitle', hasPermission('SimpleMode', 'NoQuickPass')?'_expand1':'', hasPermission('SimpleMode', 'NoNotApplicable')?'_expand2':'', hasPermission('SimpleMode', 'NoQuickPass')&&hasPermission('SimpleMode', 'NoNotApplicable')?'_expand3':'']">
          <span class="ChecksCell_Info_Header_CheckTitle_Ref">{{ template.ref }}</span>&nbsp;
          <span class="ChecksCell_Info_Header_CheckTitle_Text" :title="template.header">{{ headerPlain( template.header ) }}</span>&nbsp;
        </span>
        <span class="ChecksCell_Info_Header_Level" v-if="headerLevel( template.header )">({{ headerLevel( template.header ) }})</span>
      </h4>
      <div class="ChecksCell_Info_ExtraBtns">
        <Pill class="ChecksCell_Info_ExtraBtns_Pill" v-if="tab=='empty'&&!cellOpen&&(cell.hasUnassoc||cell.hasItemUnassoc||cell.hasClosed)&&cellStatus!='empty'" :text="cellStatus" size="small"/>
        
        <Icon class="ChecksCell_Info_ExtraBtns_UnAssoc" v-if="cell.hasUnassoc||cell.hasItemUnassoc||cell.hasClosed"
              type="solid"
              icon="exclamation-triangle"
              :aria-label="makeWarningMessage(cell.hasUnassoc, cell.hasItemUnassoc, cell.hasClosed)"
              :title="makeWarningMessage(cell.hasUnassoc, cell.hasItemUnassoc, cell.hasClosed)"/>

        <span class="ChecksCell_Info_ExtraBtns_QuickOpts">
          <Button v-if="!hasPermission('SimpleMode', 'NoQuickPass')&&editable&&cellStatus=='empty'&&!cell.hasUnassoc&&!cell.hasItemUnassoc&&!cell.hasClosed" type="green" size="nano" @click="setStatus('Pass')" :aria-label="`mark ${template.ref} check as passed`" title="mark check as passed">Pass</Button>
          <Button v-if="!hasPermission('SimpleMode', 'NoNotApplicable')&&editable&&cellStatus=='empty'&&!cell.hasUnassoc&&!cell.hasItemUnassoc&&!cell.hasClosed" type="border" size="nano" @click="setStatus('Not Applicable')" :aria-label="`mark ${template.ref} check as not applicable`" title="mark check as not applicable">Not Applicable</Button>
        </span>
        <Button v-if="editable&&cellStatus=='empty'&&!cellOpen&&!cell.hasUnassoc&&!cell.hasItemUnassoc&&!cell.hasClosed" size="micro" type="secondary" @click="toggleCell()">Begin</Button>
        <Button class="ChecksCell_Info_Btn" size="micro" :type="cellOpen?'icon':'secondary'" :icon="cellOpen?['solid', 'times']:[]" @click="toggleCell()" v-if="cellStatus!='empty'||cellOpen||cell.hasUnassoc||!editable">
          <template v-if="!cellOpen">Open</template>
          <template v-else>Close</template>
        </Button>
      </div>
    </div>
    <div v-if="cellOpen" class="ChecksCell_Details">
      <a href="#" role="button" class="ChecksCell_Details_GuidanceLink" v-if="template.details" @click="emit('guidance', template.details)">Show guidance for this check</a>
      <div v-if="cellStatus=='empty' || changeStatus" class="ChecksCell_Details_ItemGroup">
        <div class="ChecksCell_Details_ItemGroup_Item">
          <span class="ChecksCell_Details_ItemGroup_Item_Label">Select an outcome</span>
          <span class="ChecksCell_Details_ItemGroup_Item_Control">
            <div v-if="!changeStatus" @click="changeStatus = editable&&true">
              <Pill class="ChecksCell_Details_ItemGroup_Item_Control_Status" v-if="cellStatus!='empty'" :text="cellStatus" size="small"/>
              <p class="__fade" v-else>set an outcome</p>

              <div class="ChecksCell_Details_ItemGroup_Item_Control_Actions" v-if="editable">
                <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="changeStatus = editable&&true" title="Change outcome">
                  <Icon type="solid" icon="exchange-alt" aria-label="Change status"/>
                </button>
              </div>
            </div>
            <template v-else>
              <span class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts">
                <Button class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts_Button" @click="setStatus('Pass')"><Pill text="Pass" size="small" /></Button>
                <Button class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts_Button" @click="setStatus('Fail')"><Pill text="Fail" size="small" /></Button>
                <Button class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts_Button" @click="setStatus('Advisory')"><Pill text="Advisory" size="small" /></Button>
                <Button v-if="!hasPermission('SimpleMode', 'NoNotApplicable')" class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts_Button" @click="setStatus('Not Applicable')"><Pill text="Not Applicable" size="small" /></Button>
                <Button v-if="!hasPermission('SimpleMode', 'NoTodo')" class="ChecksCell_Details_ItemGroup_Item_Control_ChangeOpts_Button" @click="setStatus('Todo')"><Pill text="Todo" size="small" /></Button>
              </span>

              <!-- <div class="ChecksCell_Details_ItemGroup_Item_Control_Actions">
                <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="changeStatus = false" title="Cancel change outcome">
                  <Icon type="solid" icon="times" aria-label="cancel Change status"/>
                </button>
              </div> -->
            </template>
          </span>
        </div>
      </div>
      <div v-else class="ChecksCell_Details_ItemGroup">
        <div class="ChecksCell_Details_ItemGroup_Item">
          <span class="ChecksCell_Details_ItemGroup_Item_Control ChecksCell_Details_OutcomeSet">
            <span :class="['ChecksCell_Details_OutcomeSet_Outcome', `_${cellStatus}`]">
              <Pill :text="cellStatus" size="small"/>
              <button class="ChecksCell_Details_OutcomeSet_Outcome_Btn" v-if="editable" @click.prevent="changeStatus = editable&&true" title="Change outcome">
                <Icon type="solid" icon="exchange-alt" aria-label="Change status"/>
              </button>
            </span>
            <span class="ChecksCell_Details_OutcomeSet_Btns">
              <Button v-if="editable && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )" type="border" size="micro" @click="emit('issue', cell.row, newInstance)">Add new issue</Button>
              <Button v-if="editable && cell.type == 'page' && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )" type="border" size="micro" @click="addexistingmodal.show( cell.row, cell.column )">Add existing issue</Button>
              <Button v-if="!hasPermission( 'SimpleMode', 'NoNotes')" type="border" size="micro" @click="addNote()">Add note</Button>
            </span>
          </span>
        </div>
      </div>
      <template v-if="( ( cellStatus == 'Fail' || cellStatus == 'Advisory' ) && cellHasItems ) || forceView">
        <h5 class="ChecksCell_Details_ItemsHeading">Items: <Pill :text="cellStatus == 'empty'?tmpCell.items.length.toString():(tmpCell.items.length - 1).toString()" size="small"/></h5>
        <p class="ChecksCell_Details_ItemsText __warn" v-if="cellStatus == 'empty'">This check has issues or notes but an outcome has not been set. Please set one.</p>
        <p class="ChecksCell_Details_ItemsText __fade" v-else>This check has been marked as {{ cellStatus.toLowerCase() }}. Please capture the details of each issue on this {{ cell.type }} causing it to fail or make a note.</p>
        <template v-for="( item, index ) in tmpCell.items" :key="`item-${item.instance}`">
          <template v-if="index != 0 || cellStatus == 'empty'">
            <div class="ChecksCell_Details_ItemGroup" v-if="item.instance && item.instance != '' && ( typeof issues[ item.instance ] != 'undefined' )">
              <div class="ChecksCell_Details_ItemGroup_Item">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Instance</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <p>
                    <router-link to="#" @click.prevent="e => goToIssue( e, item.instance )">{{ item.instance }}</router-link>
                    <span v-if="issues[ item.instance ]?.status.indexOf('closed')==0" class="ChecksCell_Details_ItemGroup_Item_Control_IssueNote">
                      <Icon type="solid" icon="exclamation-triangle" />&nbsp;
                      This issue has been marked as closed
                    </span>
                    <span v-else-if="cell.type == 'page' && issues[ item.instance ]?.page?._id != cell.column && issues[ item.instance ]?.others?.map( o => o.page._id ).indexOf( cell.column ) == -1" class="ChecksCell_Details_ItemGroup_Item_Control_IssueNote">
                      <Icon type="solid" icon="exclamation-triangle" />&nbsp;
                      This issue is not on this page <button @click="addIssueToPage( item.instance )">Add it</button>
                    </span>
                  </p>
                  <div class="ChecksCell_Details_ItemGroup_Item_Control_Actions" v-if="editable">
                    <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="addNoteToItem( index )" title="Add note to item" v-if="item.text.toLowerCase() == item.instance.toLowerCase() || item.text == issues[ item.instance ]?.reason">
                      <Icon type="solid" icon="pen" aria-label="Add note to item"/>
                    </button>
                    <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="rmNote( index )" title="Delete item">
                      <Icon type="solid" icon="trash" aria-label="Delete item"/>
                    </button>
                  </div>
                </span>
              </div>
              <div class="ChecksCell_Details_ItemGroup_Item" v-if="item.text.toLowerCase() != item.instance.toLowerCase() && item.text != issues[ item.instance ]?.reason">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Note</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <input :id="`noteInput-${index}`" type="text" v-model="item.text" aria-label="Note" />
                </span>
              </div>
              <div class="ChecksCell_Details_ItemGroup_Item">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Issue template</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <p>{{ issues[ item.instance ]?.template.identifier }}: {{ issues[ item.instance ]?.template.title }}</p>
                </span>
              </div>
              <div class="ChecksCell_Details_ItemGroup_Item">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Reason</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <p>{{ issues[ item.instance ]?.reason }}</p>
                </span>
              </div>
              <div class="ChecksCell_Details_ItemGroup_Item">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Solution</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <p v-if="issues[ item.instance ]?.solutions[0]">
                    {{ issues[ item.instance ]?.solutions[0].identifier }}: {{ issues[ item.instance ]?.solutions[0].title }}&nbsp;
                    <template v-if="issues[ item.instance ]?.solutions.length>1">(and {{ issues[ item.instance ]?.solutions.length-1 }} more)</template>
                  </p>
                  <p v-else>None given</p>
                </span>
              </div>
            </div>
            <div class="ChecksCell_Details_ItemGroup  _blue" v-else>
              <div class="ChecksCell_Details_ItemGroup_Item">
                <span class="ChecksCell_Details_ItemGroup_Item_Label">Note</span>
                <span class="ChecksCell_Details_ItemGroup_Item_Control">
                  <input :id="`noteInput-${index}`" type="text" v-model="item.text" aria-label="Note" />
                  <div class="ChecksCell_Details_ItemGroup_Item_Control_Actions">
                    <!-- <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="" title="Add issue instance">
                      <Icon type="solid" icon="plus" aria-label="Add issue instance"/>
                    </button> -->
                    <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="rmNote( index )" title="Delete note">
                      <Icon type="solid" icon="trash" aria-label="Delete note"/>
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </template>
        </template>
      </template>
      <!-- <div class="ChecksCell_Details_Actions" v-if="editable">
        <Button v-if="cellStatus == 'Fail' || cellStatus == 'Advisory'" type="border" size="micro" @click="emit('issue', cell.row, newInstance)">Add new issue</Button>
        <Button v-if="cell.type == 'page' && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )" type="border" size="micro" @click="addexistingmodal.show( cell.row, cell.column )">Add existing issue</Button>
        <Button v-if="!hasPermission( 'SimpleMode', 'NoNotes')" type="border" size="micro" @click="addNote()">Add note</Button>
      </div> -->
      <!-- <div class="ChecksCell_Details_Final" v-if="isChanged&&editable">
        <span>All done?</span>
        <div>
          <Button type="secondary" size="small" @click="saveCell()">Save check</Button>
          <Button type="border" size="small" @click="cancelCell()">Cancel</Button>
        </div>
      </div> -->
      <div class="ChecksCell_Details_Potential" v-if="potentialIssues.length">
        <h5 class="ChecksCell_Details_ItemsHeading">Related issues: <Pill :text="potentialIssues.length.toString()" size="small"/></h5>
        <p class="ChecksCell_Details_ItemsText __fade">These are issues related to this check that are not currently associated.</p>
        <template v-for="(issue, i) in potentialIssues" :key="`potentialIssue-${i}`">
          <div class="ChecksCell_Details_ItemGroup" v-if="tmpCell.items.map( i => i.instance ).indexOf( issue.identifier )== -1">
            <div class="ChecksCell_Details_ItemGroup_Item">
              <span class="ChecksCell_Details_ItemGroup_Item_Label">Instance</span>
              <span class="ChecksCell_Details_ItemGroup_Item_Control">
                <p><router-link to="#" @click.prevent="e => goToIssue( e, issue.identifier )">{{ issue.identifier }}</router-link></p>
                <div class="ChecksCell_Details_ItemGroup_Item_Control_Actions">
                  <button class="ChecksCell_Details_ItemGroup_Item_Control_Actions_Btn" @click.prevent="newInstance( issue )" title="Associate issue">
                    <Icon type="solid" icon="plus" aria-label="Associate issue"/>
                  </button>
                </div>
              </span>
            </div>
            <div class="ChecksCell_Details_ItemGroup_Item">
              <span class="ChecksCell_Details_ItemGroup_Item_Label">Issue template</span>
              <span class="ChecksCell_Details_ItemGroup_Item_Control"><p>{{ issue.template.identifier }}: {{ issue.template.title }}</p></span>
            </div>
            <div class="ChecksCell_Details_ItemGroup_Item">
              <span class="ChecksCell_Details_ItemGroup_Item_Label">Reason</span>
              <span class="ChecksCell_Details_ItemGroup_Item_Control"><p>{{ issue.reason }}</p></span>
            </div>
            <div class="ChecksCell_Details_ItemGroup_Item">
              <span class="ChecksCell_Details_ItemGroup_Item_Label">Solution</span>
              <span class="ChecksCell_Details_ItemGroup_Item_Control">
                <p v-if="issue.solutions.length">
                  {{ issue.solutions[0].identifier }}: {{ issue.solutions[0].title }}&nbsp;
                  <template v-if="issue.solutions.length>1">(and {{ issue.solutions.length-1 }} more)</template>
                </p>
                <p v-else>None given</p>
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <AddExistingCheckItemModal ref="addexistingmodal" @addItem="item => addItem( item )"/>
</template>

<script setup>
  import Pill from '../components/UI/Pill.vue';

  import { toRefs, ref, computed, inject } from 'vue';
  import { useMutation, useLazyQuery } from "@vue/apollo-composable";
  import { useStore } from "vuex";
  import gql from "graphql-tag";

  import AddExistingCheckItemModal from '../modals/AddExistingCheckItem.vue';

  const addexistingmodal = ref( null );

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const props = defineProps( {
    template: Object,
    cell: Object,
    tab: String,
    editable: {
      type: Boolean,
      default: () => true,
    },
  } );

  const { template, cell, tab, editable } = toRefs( props );

  const tmpCell = ref( JSON.parse( JSON.stringify( cell.value ) ) );

  const cellOpen = ref( false );

  const potentialIssues = ref( [] );

  const { load: loadRefIssues, refetch: updateRefIssues, onResult: onRefIssues } = useLazyQuery( gql`
    query IssuesFromPageOrComponentAndReference($reference: String!, $pageOrComponent: ObjectID!) {
      issues: IssuesFromPageOrComponentAndReference(reference: $reference, pageOrComponent: $pageOrComponent) {
        _id
        identifier
        reason
        template {
          _id
          identifier
          title
        }
        solutions {
          _id
          identifier
          title
        }
      }
    }
  `,
  {
    reference: template.value.ref,
    pageOrComponent: cell.value.column,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  onRefIssues( ( { data } ) => {
    if( !data ) return;

    const { issues } = data;

    const issuesOnCell = cell.value.items.filter( i => i.instance ).map( i => i.instance.trim() ).filter( i => i != '' );

    for( const issue of issues ) {
      if( issuesOnCell.indexOf( issue.identifier.trim() ) == -1 ) potentialIssues.value.push( issue );
    }
  } );

  const editNote = index => {
    setTimeout( () => {
      document.getElementById( `noteInput-${index}` ).focus();
    } );
  };

  const forceView = ref( false );
  const addNote = () => {
    let index = 0;
    if( tmpCell.value.items[0].text != '' ) {
      //new insert index
      index = tmpCell.value.items.length;
      tmpCell.value.items.push( {
        done: false,
        instance: '',
        text: '',
      } );
    }
    forceView.value = true;
    editNote( index );
  };

  const addNoteToItem = index => {
    tmpCell.value.items[ index ].text = '';
    editNote( index );
  };

  const issues = ref( {} );

  const rmNote = index => {
    const noInstance = !tmpCell.value.items[ index ].instance || tmpCell.value.items[ index ].instance == '';
    const isClosed = issues.value[ tmpCell.value.items[ index ].instance ]?.status.indexOf( 'closed' ) == 0;
    if( noInstance || isClosed ) {
      confirm.simple().then( result => {
        if( result ) {
          tmpCell.value.items.splice( index, 1 );
        }
      } );
    } else {
      confirm.simple().then( result => {
        if( result ) {
          tmpCell.value.items.splice( index, 1 );
        }
      } );
    }
  };

  const identifiersToGet = ref( [] );

  const { load: loadIssue, refetch: refetchIssue, onResult: onIssue } = useLazyQuery( gql`
    query IssuesFromArray($identifiers: [String]!) {
      issues: IssuesFromArray(identifiers: $identifiers) {
        _id
        identifier
        reason
        template {
          _id
          identifier
          title
        }
        solutions {
          _id
          identifier
          title
        }
        page {
          _id
        }
        component {
          _id
        }
        others {
          _id
          page {
            _id
          }
        }
        status
      }
    }
  `,
  {
    identifiers: identifiersToGet,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  onIssue( ( { data } ) => {
    for( const issue of data.issues ) {
      issues.value[ issue.identifier ] = {
        ...issue,
      };
    }
  } );

  const getItemIssues = () => {
    identifiersToGet.value = tmpCell.value.items.map( i => i.instance ).filter( i => i != '' && i != null );
    // eslint-disable-next-line no-unused-expressions
    loadIssue() || refetchIssue();
  };

  const refreshPossibleIssues = () => {
    // eslint-disable-next-line no-unused-expressions
    loadRefIssues() || updateRefIssues();
  };

  const openCell = () => {
    tmpCell.value = JSON.parse( JSON.stringify( cell.value ) );
    cellOpen.value = true;
    loadRefIssues();
    getItemIssues();
  };

  const isChanged = computed( () => JSON.stringify( cell.value.items ) != JSON.stringify( tmpCell.value.items ) );

  const emit = defineEmits( [ 'saved', 'issue', 'guidance', 'close' ] );

  const container = ref( null );

  const doCloseCell = () => {
    // changeStatus.value = false;
    emit( 'close', true, container );
    setTimeout( () => {
      cellOpen.value = false;
    }, 100 );
  };

  const closeCell = () => {
    if( isChanged.value ) {
      confirm.make( `Check has unsaved changes`, 'closing would lose all changes, are you sure?' ).then( result => {
        if( result ) {
          doCloseCell();
        }
      } );
    } else {
      doCloseCell();
    }
  };

  const getCellStatus = () => {
    const statoos = [ 'fail', 'pass', 'advisory', 'na', 'not applicable' ];
    if( tmpCell.value.items.length === 0 ) tmpCell.value.items = [ {
      done: false,
      instance: '',
      text: '',
    } ];
    if( statoos.indexOf( tmpCell.value.items[0].text.toLowerCase() ) >= 0 ) return tmpCell.value.items[0].text;

    return 'empty';
  };

  const cellStatus = computed( () => getCellStatus() );

  const changeStatus = ref( getCellStatus() == 'empty' );

  const cellHasItems = computed( () => {
    if( cellStatus.value == 'empty' && tmpCell.value.items.length > 0 && ( tmpCell.value.items[0].text != '' || tmpCell.value.items[0].instance != '' ) ) return true;
    if( cellStatus.value != 'empty' && tmpCell.value.items.length > 1 && ( tmpCell.value.items[1].text != '' || tmpCell.value.items[1].instance != '' ) ) return true;

    return false;
   } );

  const { mutate: updateSpreadsheetCell } = useMutation(
    gql`
    mutation updateSpreadsheetCell($id: ObjectID!, $items: [SpreadsheetCellItemInput]) {
      cell: updateSpreadsheetCell(id: $id, items: $items) {
        _id,
        row,
        column,
        type,
        sheet
        items {
          done,
          text,
          instance,
        }
      }
    }
  ` );

  const saveCell = ( close = true ) => {
    cell.value.keepInTodo = !close;
    cell.value.items = tmpCell.value.items;
    // tmpCell.value = JSON.parse( JSON.stringify( cell.value ) );
    updateSpreadsheetCell( {
      id: cell.value._id,
      items: cell.value.items.map( item => {
        return {
          done: item.done,
          text: item.text,
          instance: item.instance,
        };
      } ),
    } ).catch( () => {
      this.$alerts.coded( 'E015', 'FXXX' ); //see notifications spreadsheet
    } );

    if( close ) {
      closeCell();

      emit( 'saved', true );
    }
  };

  const cancelCell = () => {
    tmpCell.value = JSON.parse( JSON.stringify( cell.value ) );
  };

  const toggleCell = () => {
    if( cellOpen.value == false ) {
      openCell();
    } else {
      // closeCell();
      saveCell();
    }
  };

  const setStatus = status => {
    if( cellStatus.value == 'empty' && cellHasItems.value ) {
      tmpCell.value.items.unshift( {
        done: false,
        text: status,
        instance: '',
      } );
    } else {
      tmpCell.value.items[0].text = status;
    }
    changeStatus.value = false;
    if( status == 'Pass' || status == 'Not Applicable' ) {
      saveCell();
    } else {
      saveCell( false );
    }
  };

  const newInstance = ( { identifier } ) => {
    setStatus( 'Fail' ); //XXX: should this be cleverer re advisory?
    tmpCell.value.items.push( {
      done: true,
      instance: identifier,
      text: identifier,
    } );
    //remove from possible issues
    potentialIssues.value = potentialIssues.value.filter( i => i.identifer == identifier );
    setTimeout( () => {
      getItemIssues();
      saveCell( false );
    }, 300 );
  };

  defineExpose( {
    newInstance,
  } );

  const headerRegex = /([^(^)]*)(\((Level [A]{0,3})\))?/;

  const headerPlain = header => {
    const brokenHeader = headerRegex.exec( header );
    
    if( brokenHeader[2] ) return brokenHeader[1];

    return header;
  };

  const headerLevel = header => {
    const brokenHeader = headerRegex.exec( header );
    
    if( brokenHeader[3] ) return brokenHeader[3];

    return null;
  };

  const makeWarningMessage = ( hasUnassoc, hasItemUnassoc, hasClosed ) => {
    let message = '';
    if( hasUnassoc && hasItemUnassoc && hasClosed ) {
      message = 'has unassociated, closed and items not on page';
    } else if( hasUnassoc && hasItemUnassoc ) {
      message = 'has unassociated items and items not on page';
    } else if( hasItemUnassoc && hasClosed ) {
      message = 'has closed items and items not on page';
    } else if( hasUnassoc && hasClosed ) {
      message = 'has unassociated and closed items';
    } else if( hasUnassoc ) {
      message = 'has unassociated issues';
    } else if( hasItemUnassoc ) {
      message = 'has items not on page';
    } else if( hasClosed ) {
      message = 'has closed items';
    }

    return message;
  };

  const { mutate: addIssueInstancePageByIdentifier } = useMutation(
    gql`
    mutation addIssueInstancePageByIdentifier($identifier: String!, $page: ObjectID!) {
      instance: addIssueInstancePageByIdentifier(identifier: $identifier, page: $page) {
        _id
      }
    }
  ` );

  const addIssueToPage = identifier => {
    addIssueInstancePageByIdentifier( {
      identifier,
      page: cell.value.column,
    } ).then( () => {
      alerts.success( "Issue added to page" );
      setTimeout( () => {
        getItemIssues();
      }, 300 );
    } ).catch( () => {
      alerts.coded( 'E087', 'FXXX' ); //see notifications spreadsheet
    } );
  };

  const addItem = identifier => {
    tmpCell.value.items.push( {
      text: identifier,
      instance: identifier,
      done: true,
    } );
    loadRefIssues();
    getItemIssues();
    
    addIssueToPage( identifier );
    saveCell( false );
  };

  const goToIssue = ( e, identifier ) => {
    e.preventDefault();
    emit( 'goToIssue', identifier );
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .ChecksCell {
    border: 1px solid $hugr-colours-grey;
    background: #FFF;
    margin-bottom: 16px;

    &_Info {
      position: relative;
      padding: 6px;
      &_Header {
        font-size: 11pt;
        font-weight: bold;
        margin: 6px 0;
        &_CheckTitle {
          display: inline-block;
          vertical-align: middle;
          max-width: calc( 100% - 256px );
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &._expand1 { //No pass button
            max-width: calc( 100% - 216px );
          }
          &._expand2 { //No Not applicable button
            max-width: calc( 100% - 160px );
          }
          &._expand3 { //Neither of above
            max-width: calc( 100% - 138px );
          }

          &_Ref {
            vertical-align: middle;
          }
          &_Text {
            vertical-align: middle;
          }
        }
        &_Level {
          color: lighten( $hugr-colours-primary, 25% );
        }

        &._unstarted {
          margin-left: unset;
        }
      }

      &_ExtraBtns {
        position: absolute;
        right: 8px;
        top: 8px;
        button {
          margin-left: 8px;
        }

        &_QuickOpts {
          display: inline-block;
          position: absolute;
          width: 150px;
          text-align: right;
          top: 4px;
          right: 52px;
        }
        &_UnAssoc {
          color: darken( $hugr-colours-yellow, 10% );
          vertical-align: middle;
        }
        &_Pill {
          margin-right: 16px;
          vertical-align: middle;
        }
      }
    }

    &_Details {
      padding: 6px;
      border-top: 1px solid $hugr-colours-grey;

      &_GuidanceLink {
        display: inline-block;
        font-size: 0.8em;
        text-decoration: none;
        margin: 16px 0;
      }

      &_ItemsHeading {
        font-weight: bold;
        margin-bottom: 0;
      }

      &_ItemsText {
        font-size: 0.8em;
        margin-top: 2px;
      }

      &_ItemGroup {
        border: 1px solid $hugr-colours-grey;
        margin-bottom: 16px;
        &._blue {
          background: lighten( $hugr-colours-tertiary, 70% );
          input { background: lighten( $hugr-colours-tertiary, 70% ); }
        }

        &_Item {
          border-bottom: 1px solid $hugr-colours-grey;

          &:last-child {
            border-bottom: none;
          }
          &_Label {
            display: inline-block;
            border-right: 1px solid $hugr-colours-grey;
            padding: 7px 8px 6px;
            width: calc( 20% - 20px );
            font-size: 0.9em;
          }
          &_Control {
            position: relative;
            vertical-align: top;
            display: inline-block;
            padding: 4px 8px;
            width: calc( 80% - 20px );
            height: 22px;

            font-family: "fs-me-regular", sans-serif;
            font-size: 10pt;

            &_IssueNote {
              background: lighten( $hugr-colours-red, 50% );
              padding: 4px;
              border-radius: 4px;
              margin-left: 16px;

              button {
                background: transparent;
                border: 1px solid $hugr-colours-primary;
                padding: 0px 4px;
                margin: 0;
                margin-left: 4px;
                vertical-align: middle;
              }
            }

            &_Status {
              cursor: pointer;
            }

            p {
              margin: 4px 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            > input {
              position: absolute;
              top: -1px;
              left: -1px;
              width: calc(100% - 55px);
              height: 100%;
              border: 1px solid $hugr-colours-grey;
              border-right: 0;
              padding: 0 8px;
              
              font-family: "fs-me-regular", sans-serif;
              font-size: 10pt;
            }

            &_Actions {
              position: absolute;
              right: 0;
              top: 9px;

              &_Btn {
                border: none;
                background: transparent;
                color: $hugr-colours-primary;
                cursor: pointer;
                height: 15px;
                padding: 0 2px;

                &:focus, &:hover {
                  color: $hugr-colours-tertiary;
                }
              }
            }

            &_ChangeOpts {
              &_Button {
                margin-right: 6px;
                border: none;
                padding: 0;
                background-color: transparent !important;
                border-radius: unset;
                transition: unset;

                &:focus, &:hover {
                  // TODO
                }
              }
            }
          }
        }
      }

      &_OutcomeSet {
        height: 27px;
        &_Outcome {
          padding: 4px;
          border-radius: 4px;
          vertical-align: middle;
          background: $hugr-colours-grey;

          &._Pass {
            background: lighten( $hugr-colours-green, 50%);
          }
          &._Fail {
            background: lighten( $hugr-colours-red, 50% );
          }
          &._Advisory {
            background: lighten( $hugr-colours-yellow, 30% );
          }

          &_Btn {
            background: transparent;
            border: none;
            font-size: 0.8em;
          }
        }

        &_Btns {
          margin-left: 16px;
          button {
            margin-right: 8px;
          }
        }
      }

      &_Actions {
        > button {
          margin-right: 6px;
        }
      }

      &_Final {
        position: relative;
        background: lighten( $hugr-colours-secondary, 30% );
        padding: 24px;
        margin-top: 16px;
        > div {
          position: absolute;
          right: 16px;
          top: 16px;
          button {
            margin-left: 8px;
          }
        }
      }
    }
  }

  ._darkMode .ChecksCell {
    border: 1px solid $hugr-colours-grey;
    background: $hugr-colours-primary;

    &_Info {
      &_Header {
        &_Level {
          color: darken( $hugr-colours-grey, 20% );
        }
      }
      &_ExtraBtns {
        &_UnAssoc {
          color: $hugr-colours-yellow;
        }
      }
    }
    &_Details {
      &_ItemGroup {
        &._blue {
          color: black;
          border: 1px solid lighten( $hugr-colours-primary, 40% );
          input {
            background: lighten( $hugr-colours-tertiary, 70% );
            color: black;
            border: 1px solid lighten( $hugr-colours-primary, 40% );
          }
          button { color: black; }
        }
        &_Item {
          &_Control {
            > input {
              background: $hugr-colours-primary;
              color: #FFF;
            }
            &_IssueNote {
              background: $hugr-colours-red;
            }
            &_Actions {
              &_Btn {
                color: $hugr-colours-grey;
              }
            }
          }
        }
      }

      &_Final {
        background: $hugr-colours-secondary;
        color: $hugr-colours-primary;

        button.border {
          color: $hugr-colours-primary;
          border: 1px solid $hugr-colours-primary;
          &:focus, &:hover {
            color: $hugr-colours-grey;
          }
        }
      }
    }
  }
</style>
