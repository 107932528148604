<template>
  <span class="forminput" :class="[hasError?'with-error':'']">
    <!-- <label v-bind:class="isVisible" :for="id">{{ label }}</label>
    <input  :id="id"
            :value="value"
            :placeholder="placeholder"
            ref="mainInput"
            @input="$emit('input', $event.target.value)"
            @keyup="$emit('keyup', $event)"
            @blur="$emit('blur', $event)"
            :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"
            :disabled="disabled"
            tabindex="0"
            :class="[type=='number'?'_number':'']"
          /> -->

    <fieldset :class="['FormDate', hasError?'with-error':'']">
      <legend>{{ label }}</legend>
      <div class="FormDate_FauxInput">
        <input class="FormDate_FauxInput_Input" autocomplete="off" type="number" min="01" max="31" v-model="day" aria-label="Day" @input="change" ref="day" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>/
        <input class="FormDate_FauxInput_Input" autocomplete="off" type="number" min="01" max="12" v-model="month" aria-label="Month" @input="change" ref="month" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>/
        <input class="FormDate_FauxInput_Input _year" autocomplete="off" type="number" min="2020" max="3000" v-model="year" aria-label="Year" @input="change" ref="year" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>
      </div>
      <!-- <p>Set to: {{val}}</p> -->
    </fieldset>

    <div v-if="hasError" :id="id+'-error'" class="error">{{errorMessage}}</div>
    <div v-if="hintText" :id="id+'-hint'" class="hint">{{ hintText }}</div>
  </span>
</template>

<script>
import mixin from './Mixin';

import moment from 'moment';

export default {
  mixins: [ mixin ],
  name: 'FormDate',
  data() {
      return {
        uid: this.$uuid.generate(),

        val: this.modelValue,
        day: moment( this.value ).format( "DD" ),
        month: moment( this.value ).format( 'MM' ),
        year: moment( this.value ).format( 'yyyy' ),
      };
  },
  methods: {
    focus() {
      this.$refs['day'].focus();
    },
    change() {
      if( parseInt( this.month ) < 10 ) {
        this.month = `0${parseInt( this.month )}`;
      }
      if( parseInt( this.day ) < 10 ) {
        this.day = `0${parseInt( this.day )}`;
      }
      this.val = `${this.year}-${this.month}-${this.day}T00:00:00.000Z`;
      this.$emit( 'update:modelValue', this.val );
    },
  },
  computed: {
    id() {
      return `${this.idRoot + this.uid}-${this.label.replace( ' ', '' ).toLowerCase().trim()}`;
    },
    isVisible() {
      let ret = '';
      if( !this.labelVisible ) {
        ret = 'a11yhide';
      }

      return ret;
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    hintText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  span.forminput {
    display: block;
    margin-bottom: 10px;

    .hint {
      font-size: 9pt;
      margin-top: 3px;
    }
    .error {
      font-size: 9pt;
      color: red;
    }
  }

  .FormDate {
    border: none;
    padding: 0;
    margin: 0;
    legend {
      margin: 10px 0;
      display: block;
      color: #262e37;
    }
    &_FauxInput {
      padding: 10px;
      border: 1px solid #95989c;
      color: #262e37;
      border-radius: 2px;
      width: calc(100% - 20px);
      &_Input {
        border: none;
        width: 32px;
        &._year {
          width: 50px;
        }
      }
    }

  }
  .with-error.FormDate {
    &_FauxInput {
      border: 1px solid red;
    }
  }

  ._darkMode .FormDate {
    legend {
      color: $hugr-colours-grey;
    }
    &_FauxInput {
      color: #FFF;
      &_Input {
        background: transparent;
        color: #FFF;
      }
    }
  }

  // ._darkMode span.forminput {
  //   label {
  //     color: $hugr-colours-grey;
  //   }
  //   input {
  //     background: $hugr-colours-primary;
  //     color: #FFF;
  //     &:disabled {
  //       background: darken($hugr-colours-primary, 10%);
  //       color: lighten($hugr-colours-grey, 10%);
  //     }
  //   }
  //   .hint {
  //     color: $hugr-colours-grey;
  //   }

  //   &.with-error {
  //     input {
  //       border: 1px solid #f77373;
  //     }

  //     .error {
  //       color: #f77373;
  //     }
  //   }
  // }

</style>
