<template>
  <div class="LightBoxBack" v-if="expanded" @click="toggleLightbox()"></div>
  <div :class="['Screenshot', expanded?'_expanded':'']" @click="toggleLightbox()">
    <img ref="img" :class="['Screenshot_Img', showing?'':'_preload', lightbox?'_lightbox':'', expanded?'_expanded':'']" :key="sID" :src="`${$hugrConfig.assetsUrl}/screenshot/${sID}`" :alt="renderAlt" @load="show" :tabindex="lightbox?0:-1" @click.prevent.stop="toggleLightbox()" @keyup.enter="toggleLightbox()" @keyup.space="toggleLightbox()" @keyup.esc="toggleLightbox()" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default { //TODO this should cache
  name: 'Screenshot',
  data() {
    return {
      value: '',
      options: false,
      expanded: false,
      showing: false,
    };
  },
  methods: {
    show() {
      this.showing = true;
      this.$emit( 'load' );
    },
    toggleLightbox() {
      if( this.lightbox ) this.expanded = !this.expanded;
    },
  },
  computed: {
    renderAlt() {
      if( this.lightbox && this.expanded ) return `${this.alt} (Click to close)`;
      if( this.lightbox && !this.expanded ) return `${this.alt} (Click to expand)`;

      return this.alt;
    },
  },
  props: {
    sID: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'Screenshot',
    },
    lightbox: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .Screenshot {
    width: 100%;
    &._expanded {
      background: white;
      width: 86vw;
      height: 72vh;
      position: absolute;
      left: 0;
      top: 0;
      padding: 32px;
      z-index: 9999999999999;
    }
    &_Img {
      width: 100%;
      &._preload {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
      }

      &._lightbox {
        cursor: pointer;
      }

      &._expanded {
        width: 80%;
        max-height: 100%;
      }
    }
  }

  .LightBoxBack {
    position: absolute;
    left: -500px;
    top: -500px;
    background: black;
    opacity: 0.8;
    width: 200vw;
    height: 200vh;
    z-index: 999999999999;
  }
</style>
