<template>
  <div class="forgotpassword-container">
    <div class="logo-container">
      <h1 class="logo"><span class="logo-main">Dig <span>Inclusion</span></span><span class="hugr">HUGR</span></h1>
    </div>
    <div class="login-form">
      <div class="inner">
        <span v-if="!requested">
        <h2 v-translate>Forgot password</h2>
        <form @submit.prevent="forgotPassword()">
          <span class="input">
            <FormInput ref="email" idRoot="input_" :label="$gettext('Email address')" v-model="cEmail"  :validation="['not-empty', 'email']" />
          </span>

          <Button type="secondary" htype="submit">{{$gettext('Request reset')}}</Button>
          <Button type="secondary" @click.prevent="() => { requested = true; }">{{$gettext('I already have a code')}}</Button>
        </form>
        </span>
        <span v-else>
        <h2 v-translate>Reset</h2>
        <label> {{$gettext('Please check your email for a reset code')}} </label>
        <form @submit.prevent="forgotPasswordConfirm()">
          <span class="input" v-if="!emailValidated">
            <FormInput ref="email" idRoot="input_" :label="$gettext('Email address')" v-model="cEmail"  :validation="['not-empty', 'email']" />
          </span>
          
          <span class="input">
            <FormInput ref="password" type="password" idRoot="input_" :label="$gettext('Password')" v-model="password"  :validation="['at-least-8-chars']" />
          </span>

          <span class="input">
            <FormInput ref="passwordConfirm" :initHasError="passConfirmHasError" :initErrorMessage="passConfirmErrorMessage" type="password" idRoot="input_" :label="$gettext('Confirm Password')" v-model="passwordConfirm"  :validation="['at-least-8-chars']" />
          </span>

          <span class="input">
            <FormInput ref="resetCode" idRoot="input_" :label="$gettext('Reset Code')" v-model="resetCode"  :validation="['not-empty']" />
          </span>

          <Button type="secondary" htype="submit">{{$gettext('Confirm')}}</Button>
          <Button type="secondary" @click.prevent="forgotPassword()">{{$gettext('Resend code')}}</Button>

        </form>
        </span>
        <Button type="secondary" @click.prevent="$emit('changeForm');">{{$gettext('Back to Login')}}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import md5 from 'md5';
import FormInput from '../components/Form/Input';

export default {
  name: 'ForgotPasswordView',
  data() {
    return {
      cEmail: this.email,

      password: '',
      resetCode: '',
      passwordConfirm: '',
      requested: false,
      passConfirmHasError: false,
      passConfirmErrorMessage: '',

      emailValidated: false,
    };
  },
  mounted() {
    if ( Object.prototype.hasOwnProperty.call( localStorage, "HUGR_INCORRECT_LOGIN_EMAIL" ) ) {
      const emailFetched = localStorage.getItem( 'HUGR_INCORRECT_LOGIN_EMAIL' );
      this.cEmail = emailFetched;
    }
  },
  methods: {
    forgotPassword() {
      if ( !this.requested ) {
      this.$hugrvalidate( [ 'email' ], this ).then( ( { success } ) => {
        if ( success ) {
            this.$apollo.mutate( {
              mutation: gql`mutation ($email: String!) {
                passwordResetRequest(email: $email) {
                  success,
                  extraInfo
                }
              }`,
              variables: {
                email: this.cEmail,
              },
            } ).then( res => {
              const data = res.data.passwordResetRequest;
              if ( data.success ) {
                this.$alerts.success( "Success", data.extraInfo );
                this.requested = true;
                this.emailValidated = true;
              } else {
                this.$alerts.error( "Failure", data.extraInfo );
                this.requested = false;
              }
            } );
          }
        } );
      } else {
        this.$apollo.mutate( {
          mutation: gql`mutation ($email: String!) {
            passwordResetRequest(email: $email) {
              success,
              extraInfo
            }
          }`,
          variables: {
          email: this.cEmail,
          },
        } ).then( res => {
          const data = res.data.passwordResetRequest;
          if ( data.success ) {
            this.$alerts.success( "Success", data.extraInfo );
            this.requested = true;
          } else {
            this.$alerts.error( "Failure", data.extraInfo );
            this.requested = false;
          }
        } );
      }
    },
    forgotPasswordConfirm() {
      this.$hugrvalidate( [ 'passwordConfirm', 'password', 'resetCode' ], this ).then( ( { success } ) => {
        if ( success ) {
          if ( this.password == this.passwordConfirm ) {
                this.$apollo.mutate( {
                    mutation: gql`mutation ($email: String!, $password: String!, $resetCode: String!) {
                      passwordResetConfirm(email: $email, password: $password, resetCode: $resetCode) {
                        success,
                        accessToken,
                        refreshToken,
                        user {
                          name,
                          email,
                          aclevel
                        },
                        extraInfo
                      }
                    }`,
                    variables: {
                    email: this.cEmail,
                    password: md5( `${this.password}:alienFORGERY` ),
                    resetCode: this.resetCode,
                    },
                } ).then( res => {
                  const login = res.data.passwordResetConfirm;
                  if( login.success ) {
                    this.$session.start();
                    this.$session.set( 'user', {
                      email: login.user.email,
                      name: login.user.name,
                      aclevel: login.user.aclevel,
                      accessToken: login.accessToken,
                      refreshToken: login.refreshToken,
                    } );

                    this.$session.set( 'email', login.user.email );

                    this.$session.set( 'jwt', login.accessToken );
                    localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

                    this.$session.set( 'jwt-refresh', login.refreshToken );
                    localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

                    this.setUser( {
                      email: login.user.email,
                      name: login.user.name,
                      aclevel: login.user.aclevel,
                      accessToken: login.accessToken,
                      refreshToken: login.refreshToken,
                    } );

                    this.$emit( 'success', true );

                  }

                  if ( login.extraInfo ) {
                    if ( login.success ) {
                      this.$alerts.success( "Success", login.extraInfo );
                    } else {
                      this.$alerts.error( "Failure", login.extraInfo );
                    }
                  }
                } );
          } else {
            this.passConfirmHasError = true;
            this.passConfirmErrorMessage = 'Your passwords don\'t match!';
          }
        }
      } );
    },
    ...mapActions( [ 'setUser' ] ),
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
  },
  props: [ 'email' ],
};
</script>

<style lang="scss" scoped>
  div.forgotpassword-container {
    background: #262E37;
    height: 100vh;
    color: #000;
    .logo-container {
      margin: auto;
      width: 275px;
      display: block;
      padding: 100px 0 50px;
      h1.logo {
        font-size: 1.25em;
        line-height: 1;
        span.logo-main {
          font-family: 'fs-me-bold', sans-serif;
          color: #FFF;
          border-bottom: 0;
          background-image: url(../assets/images/logo-marque-primary.png);
          background-repeat: no-repeat;
          background-position: left center;
          background-color: transparent;
          background-size: 50px;
          padding-left: 55px;
          height: 46px;
          padding-top: 10px;
          display: inline-block;
          span {
            font-family: 'fs-me-regular', sans-serif;
            font-size: 1em;
            font-weight: 300;
            display:block;
          }
        }
        .hugr {
          color: #FFF;
          border-left: 1px solid #dce4ee;
          vertical-align: top;
          padding: 25px 15px;
          font-size: 25pt;
          font-weight: normal;
          margin-left: 20px;
          top: 15px;
          position: relative;
        }
      }
    }
    .login-form {
      background:#dce4ee;
      max-width: 300px;
      margin: auto;
      padding: 20px;
      div.inner {
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 16pt;
          text-align: center;
        }
        button {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }
</style>
