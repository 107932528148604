<template>
  <Modal
    ref="modal"
    :title="$gettext('New User')"

    :showActions="true"

    :loading="submitting"
    :loadingText="inviting?'Inviting User...':'Adding User...'"

    :submitText="inviting?$gettext('Invite user'):$gettext('Add user')"
    @submit="inviting?inviteUser():submitUser()"

    @close="reset()">
    <template v-if="!inviting" >
      <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>
      <FormInput ref="email" idRoot="team_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
      <FormSelect idRoot="team_" :label="$gettext('User Type')" v-model="aclevel" :options="{
          0: $gettext('Admin'),
          1: $gettext('Tester'),
          2: $gettext('Customer')
        }"/>
      <FormSelect :key="'permission-key'" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList"/>

      <FormInput ref="password" :type="passwordType" idRoot="team_" :label="$gettext('Password')" v-model="password" :validation="['not-empty']"/>
      <Button size="micro" @click="togglePasswordVisibility">{{$gettext(passwordTypeFlavorText)}}</Button>
    </template>
    <template v-else>
      <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>
      <FormInput ref="email" idRoot="team_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
      <FormSelect idRoot="team_" :label="$gettext('User Type')" v-model="aclevel" :options="{
          0: $gettext('Admin'),
          1: $gettext('Tester'),
          2: $gettext('Customer')
        }"/>
      <FormSelect :key="'permission-key'" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList"/>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import md5 from 'md5';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'UserModal',
  data() {
    return {
      name: '',
      email: '',
      aclevel: 1,
      password: '',
      inviting: false,
      permissionGroups: false,
      selectedGroup: false,
      passwordType: 'password',
      passwordTypeFlavorText: 'Show Password',

      submitting: false,
    };
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name
          }
        }
      `,
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
      this.passwordTypeFlavorText = this.passwordType == 'password' ? 'Show Password' : 'Hide Password';
    },
    submitUser() {
      this.$hugrvalidate( [ 'name', 'email', 'password' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation addUserAdmin($user: UserInput!) {
                user: addUserAdmin(user: $user) {
                  _id
                }
              }
            `,
            variables: {
              user: {
                name: this.name,
                email: this.email,
                aclevel: parseInt( this.aclevel ),
                password: md5( `${this.password}:alienFORGERY` ),
                permissionGroup: this.selectedGroup,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.$alerts.success( 'User added!', `User ${this.name} has been added successfully` );
            this.$emit( 'success', res.data.user._id );
            this.reset();
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.generic( error );
          } );
        }
      } );
    },
    inviteUser() {
      this.$hugrvalidate( [ 'name', 'email' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation inviteUser($email: String!, $name: String!, $aclevel: Int!, $permissionGroup: ObjectID!) {
                invited: inviteUser(email: $email, name: $name, aclevel: $aclevel, permissionGroup: $permissionGroup)
              }
            `,
            variables: {
                name: this.name,
                email: this.email,
                aclevel: parseInt( this.aclevel ),
                permissionGroup: this.selectedGroup,
            },
          } ).then( res => {
            this.submitting = false;
            if ( res.data.invited ) {
              this.$alerts.success( 'User invited!', `User ${this.name} has been invited successfully.` );
              this.$emit( 'success' );
              this.reset();
            } else {
              this.$alerts.error( 'User not invited!', `User ${this.name} already exists and has signed up.` );
              this.$emit( 'success' );
              this.reset();
            }
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.generic( error );
          } );
        }
      } );
    },

    show( inviting = false ) {
      this.inviting = inviting;
      this.$refs.modal.show();
    },
    reset() {
      this.name = '';
      this.email = '';
      this.aclevel = 1;
      this.password = '';
      this.$refs.modal.reset();
    },
  },
  computed: {
    niceList() {
      const list = {};
      this.permissionGroups.forEach( group => {
        list[group._id] = group.name;
      } );

      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
  },
  components: {
    FormInput,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
