<template>
  <div class="Pill">
    <div  :class="['Pill_Main', `__size-${size}`, `__${mType}`, tooltip!=''?'_hasTooltip':'', circle?'_circle':'']"
          @mouseenter="tooltipEl?.showTooltip()"
          @mouseleave="tooltipEl?.hideTooltip()"
          @focus="tooltipEl?.showTooltip()"
          @blur="tooltipEl?.hideTooltip()"
          :tabindex="tooltip!=''?'0':'-1'"
      >
      <div class="Pill_Main_Inner" :aria-hidden="hintMode">
        {{ text }}
        <div v-if="subtext!=''" :class="['Pill_Main_Inner_Subtext', subtext!=''?'_huge':'']">
          {{ subtext }}
        </div>
      </div>
    </div>
    <Tooltip v-if="tooltip!=''" ref="tooltipEl" :text="tooltip" :title="tooltipTitle" />
  </div>
</template>

<script setup>
  import { toRefs, ref, watch } from 'vue';
  import Tooltip from './Tooltip.vue';

  const props = defineProps( {
    type: {
      type: String,
      default: 'unknown',
    },
    circle: {
      type: Boolean,
      default: false,
    },
    text: String,
    size: {
      type: String,
      default: 'normal',
    },
    subtext: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    hintMode: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
  } );

  const { type, text, size, tooltip } = toRefs( props );

  const mType = ref( type.value );
  const tooltipEl = ref( null );

  const setDefaultType = () => {
    if( type.value == 'unknown' ) {
      if( text.value == 'Pass' ) mType.value = 'positive';
      if( text.value == 'Fail' ) mType.value = 'negative';
      if( text.value == 'Advisory' ) mType.value = 'warning';

      if( text.value == 'Low' ) mType.value = 'positive';
      if( text.value == 'Medium' ) mType.value = 'warning';
      if( text.value == 'High' ) mType.value = 'negative';
      if( text.value == 'Critical' || text.value == 'Highest' ) mType.value = 'critical';
      if( text.value == 'Lowest' ) mType.value = 'advisory';
    }
  };

  setDefaultType();

  watch( text, () => {
    setDefaultType();
  } );

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Pill {
    position: relative;
    display: inline-block;

    &_Main {
      background: $hugr-colours-grey;
      color: $hugr-colours-primary;
      border-radius: 5px;
      padding: 6px;
      text-align: center;
      font-family: "Quicksand", sans-serif;
      font-size: 12pt;
      font-weight: bold;
      display: inline-block;

      &_Inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;

        &_Subtext {
          font-size: 8px;
          font-weight: normal;

          &._huge {
            font-size: 0.3em;
          }
        }
      }

      &.__positive, &.__low{
        background: $hugr-colours-pills-positive;
        color: $hugr-colours-pills-positive-text;
      }
      &.__negative, &.__high{
        background: $hugr-colours-pills-negative;
        color: $hugr-colours-pills-negative-text;
      }
      &.__warning, &.__medium{
        background: $hugr-colours-pills-warning;
        color: $hugr-colours-pills-warning-text;
      }

      &.__critical {
        background: $hugr-colours-pills-critical;
        color: $hugr-colours-pills-critical-text;
      }

      &.__advisory {
        background: $hugr-colours-pills-advisory;
        color: $hugr-colours-pills-advisory-text;
      }

      &.__inactive {
        background: $hugr-colours-pills-inactive;
        color: $hugr-colours-pills-inactive-text;
      }

      &.__size-small {
        font-size: 0.8em;
        padding: 4px;
      }
      &.__size-micro {
        font-size: 0.6em;
        padding: 2px;
      }

      &.__size-huge {
        font-size: 2.5em;
        width: 120px !important;
        height: 120px !important;
      }

      &._circle {
        border-radius: 100px;
        width: 18px;
        height: 18px;
      }

      &.__note {
        background: $hugr-colours-hint-green;
        color: #000;
      }

      &._hasTooltip {
        cursor: pointer;
      }
    }
  }
</style>
