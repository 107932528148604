<template>
  <span :class="['Tooltip', tooltipOpen?'_open':'a11yhide', flipLeft?'_flipLeft':'', flipDown?'_flipDown':'', `_closeness-${closeness}`]" ref="tooltip">
    <h5 v-if="title!=''" class="Tooltip_Title">{{ title }}</h5>
    <span class="Tooltip_Text">{{ text }}</span>
  </span>
</template>

<script setup>
  import { toRefs, ref, onUpdated, onMounted } from 'vue';

  const props = defineProps( {
    text: String,
    title: {
      type: String,
      default: '',
    },
    closeness: {
      type: String,
      default: 'normal',
    },
  } );

  const tooltip = ref( null );

  const { text, title } = toRefs( props );

  const tooltipOpen = ref( false );
  const flipLeft = ref( false );
  const flipDown = ref( false );

  const checkBounds = () => {
    const bounds = tooltip.value.getBoundingClientRect();

    const leftEdge = bounds.x;
    if( leftEdge < 0 ) flipLeft.value = false;

    const rightEdge = bounds.x + bounds.width;
    if( rightEdge > window.innerWidth ) flipLeft.value = true;

    const topEdge = bounds.y;
    if( topEdge < 0 ) flipDown.value = true;
  };

  onUpdated( () => checkBounds() );
  onMounted( () => checkBounds() );

  const showTooltip = () => {
    tooltipOpen.value = true;
    checkBounds();
  };

  const hideTooltip = () => {
    tooltipOpen.value = false;
  };

  defineExpose( {
    showTooltip,
    hideTooltip,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Tooltip {
    display: block;
    position: absolute;
    background: lighten( $hugr-colours-grey, 10% );
    width: 200px;
    padding: 8px;
    text-align: left;
    border-radius: 8px;
    color: black;

    bottom: 44px;
    left: -16px;
    
    font-size: 1em;

    &._closeness-close {
      bottom: 32px;
    }

    &._closeness-far {
      bottom: 100px;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid lighten( $hugr-colours-grey, 10% );
      position: absolute;
      bottom: -10px;
      left: 21px;
    }

    &_Title {
      margin: 0;
      font-weight: bold;
      font-size: 1em;
    }
    &_Text {
      font-size: 0.9em;
      font-weight: normal;
    }
  }

  ._flipLeft.Tooltip {
    left: unset;
    right: -16px;

    &:after {
      left: unset;
      right: 21px;
    }
  }

  // ._flipDown.Tooltip {
  //   top: 42px;
    
  //   &:after {
  //     display: none;
  //   }

  //   &:before {
  //     content: '';
  //     width: 0;
  //     height: 0;
  //     border-left: 10px solid transparent;
  //     border-right: 10px solid transparent;
  //     border-top: 10px solid lighten( $hugr-colours-grey, 10% );
  //     position: absolute;
  //     // bottom: -10px;
  //     // left: 21px;
  //   }
  // }

  ._darkMode .Tooltip {
    &_Title {
      color: #000 !important;
    }
  }
</style>
