<template>
  <!-- <i :class="cStyle+' fa-'+icon" :aria-label="read"></i> -->
  <font-awesome-icon :icon="[cStyle, icon]" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <!-- <FontAwesomeIcon :icon="icon" :aria-label="read"/> -->
</template>

<script>
export default {
  name: 'UIIcon',
  computed: {
    cStyle() {
      let retStyle = 'far';
      switch( this.type ) {
        case 'solid': {
          retStyle = 'fas';

          break;
        }
        case 'light': {
          retStyle = 'fal';

          break;
        }
        case 'brands': {
          retStyle = 'fab';

          break;
        }
        case 'duutone': {
          retStyle = 'fad';

          break;
        }
      }

      return retStyle;
    },
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'regular',
    },
    read: {
      type: String,
      default: '',
    },
    colour: {
      type: String,
      default: '',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
