<template>
  <div v-bind:class="['Body', (aside?'_withaside':'')]">
    <div :class="['Body_Menu', menuOpen?'_open':'_closed']" v-if="withMenu">
      <button class="Body_Menu_Button" @click="toggleMenu" v-if="!menuOpen">
        <span class="Body_Menu_Button_Icon"><Icon type="solid" icon="ellipsis-v"/></span>
        <span class="Body_Menu_Button_Text" v-translate>Menu</span>
      </button>
      <Menu ref="menu" v-if="menuOpen" @close="toggleMenu" @changes="changes"/>
    </div>
    <div v-else-if="menuItems.length" class="Body_IncMenu">
      <LinkButton
        v-for="menuItem in menuItems"
        :key="menuItem.display"
        class="Body_IncMenu_Button"
        :active="menuItem.active"
        :icon="menuItem.icon"
        type="transparent"
        direction="vertical"
        size="small"
        :to="menuItem.location">
        {{$gettext(menuItem.display)}}
      </LinkButton>
    </div>
    <div :class="['Body_Container', '_fill', withMenu||menuItems.length?'_withMenu':'', menuOpen||menuItems.length?'_menuOpen':'_menuClosed']">
      <div v-bind:class="['Body_Container_Inner', `_width-${width}`, noPadBottom?'_noPadBottom':'', noPadTop?'_noPadTop':'']">
        <BreadCrumbs v-if="trail.length" :trail="trail" />
        <template v-if="title!=''">
          <div :class="'Body_Container_Inner_Container'">
            <Icon v-if="titleIcon.length > 0" :type="titleIcon[0]" :icon="titleIcon[1]" class="Body_Container_Inner_Container_Icon"/>
            <h3 v-if="headingLevel=='3'" :class="['Body_Container_Inner_Container_Title', emphasise?'_emphasise':'']">{{ title }}</h3>
            <h2 v-else :class="['Body_Container_Inner_Container_Title', emphasise?'_emphasise':'']">{{ title }}</h2>
            <Pill :hintMode="true" :text="'?'" v-if="titleHint.length > 0" :tooltipTitle="titleHint.length>=0?titleHint[0]:''" :tooltip="titleHint.length>=1?titleHint[1]:''" :type="'positive'" :circle="true" size="micro" />
          </div>
        </template>
        <p :class="['Body_Container_Inner_SubTitle', emphasise?'_emphasise':'']" v-if="subtitle!=''" >{{subtitle}}</p>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ReportMenu from '../ReportMenu';
import Pill from './Pill';

export default {
  name: 'UIBody',
  data() {
    return {
      menuOpen: window.innerWidth > 900,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    changes() {
      this.$emit( 'changes' );
    },
    reloadMenu() {
      this.$refs.menu.reload();
    },
  },
  components: {
    Menu: ReportMenu,
    Pill,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fill: { //TODO this - this is height
      type: Boolean,
      default: false,
    },
    withMenu: {
      type: Boolean,
      default: false,
    },
    aside: {
      type: Boolean,
      default: false,
    },
    emphasise: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    titleIcon: {
      type: Array,
      default: () => [],
    },
    titleHint: {
      type: Array,
      default: () => [],
    },
    trail: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: 'full',
    },
    headingLevel: {
      type: String,
      default: '2',
    },
    noPadBottom: {
      type: Boolean,
      default: () => false,
    },
    noPadTop: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_menu.scss';

  .Body {
    min-height: 100%;
    background: #FFF;
    // overflow: hidden;
    position: relative;
    width: 100%;
    transition: background-color .5s ease-in-out 0s;

    padding-bottom: 32px;

    &._withaside {
      width: calc(100% - 271px);
    }

    &_IncMenu {
      display: inline-block;
      vertical-align: top;
      width:  $hugr-body-menu-width-open;
      height: calc( 100vh - 100px );
      padding-left: 50px;
      padding-top: 20px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &_Button {
        margin-bottom: 8px;
      }
    }

    &_Menu {
      display: inline-block;
      vertical-align: top;
      width: $hugr-body-menu-width-closed;
      padding-left: 50px;
      padding-top: 20px;

      transition: all .5s ease-in-out 0s;

      &._open {
        width:  $hugr-body-menu-width-open;
        height: calc( 100vh - 100px );
      }

      &_Button {
        background: transparent;
        border: none;
        cursor: pointer;
        color: lighten($hugr-colours-primary, 25%);
        transition: all .5s ease-in-out 0s;

        &:hover, &:focus, &.focus {
          color: $hugr-colours-primary;
          .Body_Menu_Button_Icon {
            border: 2px solid $hugr-colours-primary;
          }
        }

        &_Icon {
          border: 2px solid lighten($hugr-colours-primary, 25%);
          display: block;
          width: 5px;
          height: 14px;
          padding: 12px 17px;
          border-radius: 21px;
        }
        &_Text {
          text-transform: uppercase;
        }
      }
    }

    &_Container {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      &._withMenu {
        transition: all .5s ease-in-out 0s;
        width: calc(100% - #{$hugr-body-menu-width-closed} - 50px);
        &._width-full {
          margin-left: calc( $hugr-body-menu-width-closed + 50px );
        }
        &._menuOpen {
          width: calc(100% - #{$hugr-body-menu-width-open} - 50px);
          &._width-full {
            margin-left: calc( $hugr-body-menu-width-open + 50px );
          }
        }
      }

      &._fill {
        min-height: 100%;
      }

      &_Inner {
        display: block;

        background: #FFF;
        padding: 20px 50px;
        box-sizing: border-box;
        margin: auto;

        transition: background-color .5s ease-in-out 0s;

        width: 100%;

        &_Container {
          display: flex;
          flex-direction: row;
          column-gap: 10px;
          flex-wrap: nowrap;
          align-items: center;
          margin: 32px 8px;

          &_Title {
            margin: 0;
            &._emphasise {
              font-size: 2em;
            }
          }

          &_Icon {
            width: 28px;
            height: 28px;
          }
        }

        &._width-narrow {
          max-width: 1200px;
        }

        &._noPadBottom {
          padding-bottom: 0;
        }

        &._noPadTop {
          padding-top: 0;
        }

        &_SubTitle {
          font-size: 0.8em;
          margin-top: 0;
          position: relative;
          color: lighten($hugr-colours-primary, 25%);

          top: -20px;
          &._emphasise {
            top: -30px;
          }
        }
      }
    }

  }

  ._darkMode {
    .Body {
      background-color: $hugr-colours-primary;
      color: white;
      &_Menu {
        &_Button {
          color: darken(white, 25%);
          &:hover, &:focus, &.focus {
            color: white;
            .Body_Menu_Button_Icon {
              border: 2px solid white;
            }
          }
          &_Icon {
            border: 2px solid darken(white, 25%);
          }
        }
      }
      &_Container {
        &_Inner {
          background-color: $hugr-colours-primary;
          color: white;
          &_SubTitle {
            color: darken($hugr-colours-grey, 10%);
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .Body {
      overflow: hidden;
      white-space: nowrap;

      &._withaside {
        width: 100%;
      }

      &_Menu {
        padding-left: 20px;
      }
      &_Container {
        &_Inner {
          padding: 10px 20px;
          white-space: normal;
          // width: calc(100% - 40px) !important;
          &._withMenu {
            width: calc(100% - 50px - 20px);
            &._menuOpen {
              width: calc(100% - 50px - 20px); //same as above, don't need to fit in with menu open in mob
            }
          }

          &_Title {
            white-space: normal;
          }
        }
      }
    }
  }
</style>
