<template>
  <div class="aside" ref="aside">
    <div class="inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Aside',
  mounted() {
    window.addEventListener( 'scroll', this.scroll );
    this.offset = this.$refs.aside.getBoundingClientRect().top;
  },
  beforeUnmount() {
    window.removeEventListener( 'scroll', this.scroll );
  },
  data() {
    return {
      offset: 0,
    };
  },
  methods: {
    scroll( e ) {
      const fromTop = this.offset - window.scrollY;
      if( fromTop <= 100 ) {
        this.$refs.aside.classList.add( '_fixed' );
        this.$refs.aside.style.top = '100px';
      } else {
        this.$refs.aside.classList.remove( '_fixed' );
        this.$refs.aside.style.top = `${240 - window.scrollY}px`;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '@/assets/styles/variables/_colours.scss';

  div.aside {
    position: fixed;
    right: 0;
    top: 240px;
    height: calc(100vh - 141px);
    width: 250px;
    background: white;
    border-left: 1px solid #dce4ee;
    padding: 10px;
    overflow: auto;

    transition: background-color .5s ease-in-out 0s;

    &._fixed {
      position: fixed;
      top: 100px;
    }

    &::-webkit-scrollbar {
      width: 0;
      transition: width 1s;
    }
    &::-webkit-scrollbar-thumb {
      background: #FFFFFFbf;
    }
    &:hover {
      &::-webkit-scrollbar {
        width: 5px;
      }
    }

    dl {
      & > div {
        font-size: 10pt;
        dt {
          display: block;
          font-weight: bold;
        }
        dd {
          display: block;
          margin: 5px 0px 30px;

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {}
          }
        }
      }
    }
  }

  ._darkMode div.aside {
    background: $hugr-colours-primary;
  }
</style>
