<template>
  <Pill v-if="progress==0" text="Untested" />
  <Pill v-else-if="progress<100&&isCompliant" text="In Progress" />
  <Pill v-else-if="isCompliant&&issues>0" :text="truncate?`Advisories`:`Pass with advisories`" type="warning" :title="truncate?`Pass with advisories`:''"/>
  <Pill v-else-if="isCompliant" text="Pass" type="positive"/>
  <Pill v-else text="Fail" type="negative"/>
</template>

<script setup>
import Pill from '../components/UI/Pill.vue';

import { toRefs } from 'vue';

const props = defineProps( {
  progress: Number,
  isCompliant: Boolean,
  issues: Number,
  truncate: {
    type: Boolean,
    default: false,
  },
} );

const { progress, isCompliant, issues, truncate } = toRefs( props );
</script>
