<template>
  <Modal
    ref="modal"
    title="Delete page"
    :showActions="true"
    submitText="Delete page"

    :loading="deleting"
    loadingText="Deleting page..."

    @submit="deletePage()"
    @close="reset()">
    <div class="RemovePage">
      <p v-if="page.issues.filter( i => i.primary ).length>0">This page has {{ page.issues.filter( i => i.primary ).length }} issues that will be deleted. If you would like to keep these issues add them to another page first.</p>
      <p v-if="page.issues.filter( i => i.others.length>0 ).length>0">This page has {{ page.issues.filter( i => i.others.length>0 ).length }} issues on other pages, these will be kept.</p>
      <p v-if="page.components.length>0">This page has {{ page.components.length }} components, this page will be removed from them.</p>
      <p v-if="page.userJourneys.length>0">This page is on {{ page.userJourneys.length }} user journeys, this page will be removed from them.</p>
    </div>
  </Modal>
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useLazyQuery, useMutation } from '@vue/apollo-composable';
  import gql from "graphql-tag";

  const modal = ref( null );
  
  const pageid = ref( null );
  const enabled = ref( false );
  const deleting = ref( false );

  const page = ref( null );

  const confirm = inject( 'confirm' );

  const emit = defineEmits( [ 'doReset' ] );

  const { load: loadPage, refetch: updatePage, onResult: onPage } = useLazyQuery( gql`
    query Page($id: ObjectID!) {
      page: Page(id: $id) {
        _id
        name
        issues {
          _id
          identifier
          primary
          others {
            _id
          }
        }
        components {
          _id
          identifier
        }
        userJourneys {
          _id
        }
      }
    }
  `,
  {
    id: pageid,
  },
  {
    fetchPolicy: 'no-cache',
    enabled,
  } );

  onPage( ( { data } ) => {
    page.value = data.page;

    modal.value.show();
  } );

  const show = pageida => {
    pageid.value = pageida;
    enabled.value = true;

    // eslint-disable-next-line no-unused-expressions
    loadPage() || updatePage();
  };

  const reset = () => {
    pageid.value = null;
    page.value = null;
    enabled.value = false;

    modal.value.reset();
  };

  const { mutate: removePage } = useMutation(
    gql`
    mutation removePageComplex($id: ObjectID!) {
      removed: removePageComplex(id: $id)
    }
  ` );

  const deletePage = () => {
    confirm.simple( 'This cannot be undone' ).then( result => {
      if( result && page.value ) {
        enabled.value = false;
        removePage( {
          id: page.value._id,
        } ).then( () => {
          emit( 'doReset', true );
          reset();
        } );
      }
    } );
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
  // @import '@/assets/styles/variables/_colours.scss';
  // .RemovePage {
  //   padding: 16px;
  // }
</style>
