/**
 * Font awesome imports
 */
 import { library } from '@fortawesome/fontawesome-svg-core';
 import {
   faTimes,
   faSignOutAlt,
   faCog,
   faThList,
   faTh,
   faToggleOn,
   faToggleOff,
   faChevronRight,
   faChevronLeft,
   faChevronUp,
   faChevronDown,
   faUpload,
   faArchive,
   faTrash,
   faLink,
   faTable,
   faDownload,
   faFileExport,
   faHome,
   faUserAlt,
   faUserCog,
   faVial,
   faFolderPlus,
   faTools,
   faPuzzlePiece,
   faBug,
   faEnvelopeOpenText,
   faArrowUp,
   faArrowDown,
   faArrowLeft,
   faArrowRight,
   faMinus,
   faExclamationTriangle,
   faObjectGroup,
   faCopy,
   faExpandArrowsAlt,
   faExpandAlt,
   faCompressAlt,
   faReply,
   faUsers,
   faUserPlus,
   faPlus,
   faSearch,
   faInfoCircle,
   faEllipsisV,
   faCheck,
   faBell,
   faTicketAlt,
   faCommentAlt,
   faExternalLinkAlt,
   faExchangeAlt,
   faShareSquare,
   faBars,
   faKey,
   faLock,
   faUnlock,
   faEnvelope,
   faMoon,
   faUsersCog,
   faAsterisk,
   faImage,
   faHouseUser,
   faIdCard,
   faCircleXmark,
   faCircleCheck,
   faCircleExclamation,
   faCode,
   faRoute,
   faPen,
   faLinkSlash,
   faRocket,
   faCaretRight,
   faFilter,
   faStar,
   faPaperclip,
   faChartPie,
   faQuestion,
   faGlobe,
   faThumbtack,
 } from '@fortawesome/free-solid-svg-icons';
 import {
   faEdit,
   faClock,
   faFolder,
   faFolderOpen,
   faFile,
   faClone,
   faLightbulb,
   faEye,
   faEyeSlash,
   faPaperPlane,
   faCreditCard,
   faSquare,
   faSquareCheck,
   faCircleCheck as farCircleCheck,
   faImage as farImage,
   faStar as farStar,
   faClipboard,
 } from '@fortawesome/free-regular-svg-icons';
 import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add( [
    faTimes,
    faSignOutAlt,
    faCog,
    faThList,
    faTh,
    faToggleOn,
    faToggleOff,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faDownload,
    faChevronDown,
    faUpload,
    faArchive,
    faTrash,
    faLink,
    faTable,
    faFileExport,
    faHome,
    faUserAlt,
    faUserCog,
    faVial,
    faFolderPlus,
    faTools,
    faPuzzlePiece,
    faBug,
    faEnvelopeOpenText,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faMinus,
    faExclamationTriangle,
    faObjectGroup,
    faCopy,
    faExpandArrowsAlt,
    faExpandAlt,
    faCompressAlt,
    faReply,
    faUsers,
    faUserPlus,
    faPlus,
    faSearch, //would be better as regular or thin
    faInfoCircle, //this would be better as regular or thin
    faEllipsisV,
    faCheck,
    faBell,
    faTicketAlt,
    faCommentAlt,
    faExternalLinkAlt,
    faExchangeAlt,
    faBars,
    faKey,
    faLock,
    faUnlock,
    faEnvelope,
    faMoon,
    faUsersCog,
    faAsterisk,
    faImage,
    faHouseUser,
    faIdCard,
    faCircleXmark,
    faCircleCheck,
    faCircleExclamation,
    faCode,
    faRoute,
    faPen,
    faLinkSlash,
    faRocket,
    faCaretRight,
    faFilter,
    faStar,
    faPaperclip,
    faGlobe,
    faChartPie,
    faQuestion,
    faThumbtack,

    faEdit,
    faClock,
    faFolder,
    faFolderOpen,
    faFile,
    faClone,
    faLightbulb,
    faEye,
    faEyeSlash,
    faPaperPlane,
    faShareSquare,
    faCreditCard,
    faSquare,
    faSquareCheck,
    farCircleCheck,
    farImage,
    farStar,
    faClipboard,
  ] );

  export default FontAwesomeIcon;
