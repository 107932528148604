<template>
  <router-link :class="[
      type,
      (direction==''?'direction-'+signal.direction:'direction-'+direction),
      (size==''?'size-'+signal.size:'size-'+size),
      (sub?'withsub':''),
      active?'_active':'',
    ]"
    :aria-current="active?'page':'false'"
    :to="to" ref="button">
    <span class="positioner">
      <span class="centerer">
        <template v-if="icon.length&&(typeof icon[2]=='undefined'||icon[2]!='after')">
          <Icon class="icon" :type="icon[0]" :icon="icon[1]"/>
          &nbsp;
        </template>
        <span>
          <slot></slot>
        </span>
        <span class="sub" v-if="sub">
          {{sub}}
        </span>
        <template v-if="icon.length&&(typeof icon[2]!='undefined')&&icon[2]=='after'">
          &nbsp;
          <Icon class="icon after-icon" :type="icon[0]" :icon="icon[1]"/>
        </template>
      </span>
      <Pill class="pill" v-if="pill!=''" :text="pill" size="small" />
    </span>
  </router-link>
</template>

<script>
import UIIcon from '@/components/UI/Icon';
import Pill from '@/components/UI/Pill';

export default {
  name: 'UILinkButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    to: {
      type: String,
      default: '',
    },
    signal: {
      type: Object,
      default: () => {
        return {
          direction: 'horizontal',
          size: 'normal',
        };
      },
    },
    direction: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    sub: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: String,
      default: '',
    },
  },
  methods: {
    focus() {
      this.$refs.button.focus();
    },
  },
  components: {
    Icon: UIIcon,
    Pill,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_buttons.scss';

button, a {
  font-family: $hugr-button-all-font;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border: 0;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color .5s ease-in-out 0s,
              color .5s ease-in-out 0s,
              border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  // padding-right: 8rem;
  text-align: left;
  display: inline-block;
  font-size: 1em;

  border-radius: 3px;

  .positioner {
    display: table;
    height: 100%;
    width: 100%;
    .centerer {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }
  }

  &:focus, &:hover, &.focus, &._active, &[aria-current]:not([aria-current="false"]) {
    border-radius: 6px;
  }

  .icon {
    margin-right: 5px;
  }
  .after-icon {
    float: right;
    margin-left: 5px;
    margin-top: 2px;
  }

  &.withsub {
    .icon {
      margin-top: -6px;
    }
  }
  .sub {
    display: inline-block;
    font-size: 9pt;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.primary {
    color: $hugr-button-primary-text;
    background-color: $hugr-button-primary-bg;
    &:focus, &:hover, &.focus, &.hover, &._active, &[aria-current]:not([aria-current="false"]) {
      // color: $hugr-button-primary-text-focus;
      font-weight: 400;
      background-color: darken($hugr-button-primary-bg, 15%);
    }
  }

  &.secondary {
    color: $hugr-button-secondary-text;
    background-color: $hugr-button-secondary-bg;
    &:focus, &:hover, &.focus, &.hover, &._active, &[aria-current]:not([aria-current="false"]) {
      // color: $hugr-button-secondary-text-focus;
      background-color: darken($hugr-button-secondary-bg, 20%);
    }
  }

  &.serious {
    color: $hugr-button-serious-text;
    background-color: $hugr-button-serious-bg;
    &:focus, &:hover, &.focus, &.hover, &._active, &[aria-current]:not([aria-current="false"]) {
      // color: $hugr-button-serious-text-focus;
      background-color: darken($hugr-button-serious-bg, 15%);
    }
  }

  &.transparent {
    color: $hugr-button-transparent-text;
    background-color: $hugr-button-transparent-bg;
    border: 1px solid $hugr-button-transparent-border;
    &:focus, &:hover, &.focus, &.hover {
      background: $hugr-button-transparent-bg-focus;
      border: 1px solid $hugr-button-primary-bg;
    }
    &._active, &[aria-current]:not([aria-current="false"]) {
      background: $hugr-button-transparent-bg-focus;
    }
  }

  &.border {
    color: $hugr-button-border-text;
    background-color: $hugr-button-border-bg;
    border: 1px solid $hugr-button-border-border;
    &:focus, &:hover, &.focus, &.hover {
      background-color: $hugr-button-border-border;
      border: 1px solid $hugr-button-primary-bg;
    }
    &._active, &[aria-current]:not([aria-current="false"]) {
      background-color: $hugr-button-border-border;
      border: 1px solid $hugr-button-primary-bg;
    }
  }

  &.icon {
    background: transparent;
    width: 26px;
    height: 26px;
    display: inline-block;
    padding: 0;
    span span span {
      color: transparent;
    }
  }

  &.switch {
    background: transparent;
    // width: 20px;
    // height: 20px;
    display: inline-block;
    padding: 0;
    color: #262e37;
    background-color: #ffb23e;
    &[aria-checked="true"] {
      color: #ffb23e;
      background-color: #262e37;
    }
  }

  &.edit {
    padding: 5px 5px 5px 24px;
    background: transparent;
    color: #5e646a;
    border: 1px solid transparent;

    &:hover, &:focus {
      color: #262e37;
      border: 1px solid #5e646a;
    }

    &:before {
      content: "\270E";
      left: 0;
      top: 55%;
    }
  }

  //size and direction
  &.direction-vertical {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  &.size-small {
    padding: 10px 10px 10px 10px;
    font-size: 10pt;
    .icon {
      width: 13px;
      height: 13px;
    }
  }
  &.size-micro {
    padding: 5px;
    font-size: 10pt;
    &:before { //this might not be needed
      content: '';
    }
  }
}

._darkMode button, ._darkMode a {
  &.primary {
    background-color: $hugr-colours-grey;
    color: $hugr-button-secondary-text;
  }

  &.border {
    color: $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover {
      background-color: darken($hugr-button-primary-bg, 10%);
      border: 1px solid $hugr-colours-grey;
    }
    &._active, &[aria-current]:not([aria-current="false"])  {
      background-color: darken($hugr-button-primary-bg, 10%);
    }
  }

  &.transparent {
    color: $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover {
      background-color: darken($hugr-button-primary-bg, 10%);
      border: 1px solid $hugr-colours-grey;
    }
    &._active, &[aria-current]:not([aria-current="false"])  {
      background-color: darken($hugr-button-primary-bg, 10%);
    }
  }

  &.serious {
    background-color: darken($hugr-button-serious-bg, 10%);
    border: 1px solid $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover, &._active, &[aria-current]:not([aria-current="false"])  {
      background-color: darken($hugr-button-serious-bg, 25%);
    }
  }

  .pill {
    float: right;
  }
}
</style>
