<template>
  <div class="Fragment" v-if="isShow">
    <div v-if="stageClosure" class="Fragment_StageClosure" aria-live="polite"><p>Click outside again to close</p></div>
    <div class="Fragment_Inner" ref="fragmentwindow" tabindex="-1" role="dialog" aria-labelledby="fragmenttitle" aria-modal="true">
      <div class="Fragment_Inner_Head">
        <Button class="Fragment_Inner_Head_Close" type="icon" :icon="['solid', 'times']" @click="() => { reset() }">close</Button>
      </div>
      <div class="Fragment_Inner_Body">
        <h1 :class="['Fragment_Inner_Body_Title', halfTitle?'_half':'']" id="fragmenttitle" :title="title">
          <Icon v-if="icon" class="Fragment_Inner_Body_Title_Icon" :type="icon[0]" :icon="icon[1]"/>&nbsp;
          {{ title }}
        </h1>
        <h2 v-if="subtitle != ''" :class="['Fragment_Inner_Body_SubTitle', halfTitle?'_half':'']">{{ subtitle }}</h2>
        <Button v-if="showBtn" class="Fragment_Inner_Body_Button" type="icon" :icon="btnIcon" @click="btnAction">{{ btnLabel }}</Button>
        <slot></slot>
        <!-- <h2>{{  title }}</h2>
        <div>
          <h3>{{ type }} compliance:</h3>
          <span>{{  compliance }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UIFragment",
  };
</script>

<script setup>
  import { ref, toRefs } from 'vue';

  const TAB_KEY = 9;
  const TIMEOUT = 100;

  const props = defineProps( {
    icon: Object,
    halfTitle: {
      type: Boolean,
      default: false,
    },
    title: String,
    subtitle: {
      type: String,
      default: '',
    },
    type: String,
    compliance: String,

    btnIcon: Object,
    btnLabel: String,
    btnAction: Function,

    showBtn: {
      type: Boolean,
      default: () => true,
    },
  } );

  const {
    icon,
    title,
    type,
    compliance,

    btnIcon,
    btnLabel,
    btnAction,

    showBtn,
  } = toRefs( props );

  const isShow = ref( false );
  const stageClosure = ref( false );

  const fragmentwindow = ref( null );

  const activatingButton = ref( false );

  const tab = e => { //IT'S A TARP
    if( e.keyCode == TAB_KEY ) {
      if( !fragmentwindow.value.querySelectorAll( ":focus" ).length && document.activeElement != fragmentwindow.value ) {
        fragmentwindow.value.focus();
      }
    }
  };

  const show = () => {
    document.addEventListener( 'keyup', tab );
    isShow.value = true;

    activatingButton.value = document.activeElement;
    setTimeout( () => {
      fragmentwindow.value.focus();
    }, TIMEOUT );
  };

  const emit = defineEmits( [ 'closed' ] );

  const reset = () => {
    document.removeEventListener( 'keyup', tab );
    emit( 'closed', true );
    isShow.value = false;
    if( activatingButton.value && typeof activatingButton.value != 'undefined' && "focus" in activatingButton.value && typeof activatingButton.value.focus == 'function' ) {
      setTimeout( () => {
        try {
          activatingButton.value.focus();
          activatingButton.value = false;
        } catch( e ) {
          console.log( "Couldn't focus activating button" );
        }
      }, 300 );
    }
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Fragment {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    background: rgba(38, 46, 55, 0.5);

    &_Inner {
      z-index: 9999999;
      width: 95%;
      height: calc(100vh - 116px);
      position: absolute;
      bottom: 68px;
      left: 2.5%;

      &_Head {
        background: $dig-blue;
        height: 32px;

        &_Close {
          position: absolute !important;
          right: 0px;
          top: -1px;
          color: #FFF !important;
        }
      }

      &_Body {
        border: 1px solid #c6cdd7;
        background: white;
        height: calc( 100% - 32px );
        padding: 32px;

        &_Title {
          display: inline-block;
          font-size: 1.6em;
          font-weight: 600;
          margin-top: 0;
          color: $hugr-colours-primary;
          max-width: 60%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          &_Icon {
            color: lighten( $hugr-colours-primary, 20% );
          }

          &._half {
            max-width: 49%;
            font-size: 1.4em;
          }
        }
        &_SubTitle {
          font-size: 1.2em;
          font-weight: bold;
          color: lighten( $hugr-colours-primary, 20% );
          margin-top: -16px;
          margin-left: 42px;
          &._half {
            font-size: 1em;
            margin-left: 36px;
          }
        }
        &_Button {
          display: inline-block;
          position: relative;
          top: -8px;
          left: 6px;
        }
      }
    }
  }

  ._darkMode .Fragment {
    &_Inner {
      &_Head {

      }

      &_Body {
        background: $hugr-colours-primary;
        border: 1px solid $dig-blue;

        &_Title {
          color: #FFF;
          &_Icon {
            color: #FFF;
          }
        }
      }
    }
  }
</style>
